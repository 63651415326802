var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.list, function (item, index) {
      return _c("div", { key: index, staticClass: "flex standard" }, [
        _c("div", { staticClass: "type-name" }, [
          _c("p", { staticClass: "name" }, [
            _vm._v(" " + _vm._s(item.participatorName) + " "),
            !_vm.notView
              ? _c("a", {
                  staticClass: "el-icon-error close",
                  on: {
                    click: function () {
                      return _vm.delStandard(_vm.rowIndex, index)
                    },
                  },
                })
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticStyle: { padding: "5px", "background-color": "#F9F9F9" } },
          _vm._l(item.scanCodeExpressions, function (child, cIndex) {
            return _c(
              "div",
              { key: cIndex, staticClass: "standard-detail" },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("RuleRender", {
                      attrs: { ladder: child.ladder, notView: !_vm.notView },
                    }),
                    cIndex === 0 && !_vm.notView
                      ? _c("a", {
                          staticClass: "el-icon-circle-plus rowLineIcon",
                          on: {
                            click: function () {
                              return _vm.addRowStandard(
                                _vm.rowIndex,
                                index,
                                cIndex,
                                child
                              )
                            },
                          },
                        })
                      : cIndex !== 0 && !_vm.notView
                      ? _c("a", {
                          staticClass: "el-icon-remove rowLineIcon closeIcon",
                          on: {
                            click: function () {
                              return _vm.delRowStandard(
                                _vm.rowIndex,
                                index,
                                cIndex
                              )
                            },
                          },
                        })
                      : _vm._e(),
                    _c("div"),
                  ],
                  1
                ),
                _vm._l(child.dealerList, function (dealer, dIndex) {
                  return _c(
                    "div",
                    {
                      key: dIndex,
                      staticClass: "flex",
                      staticStyle: { "justify-content": "space-between" },
                    },
                    [
                      _c("RuleRender", {
                        staticClass: "render",
                        attrs: { ladder: dealer, notView: !_vm.notView },
                      }),
                      !_vm.notView
                        ? _c("a", {
                            staticClass: "el-icon-remove rowLineIcon closeIcon",
                            on: {
                              click: function () {
                                return _vm.delDealerLadder(
                                  _vm.rowIndex,
                                  index,
                                  cIndex,
                                  dIndex
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                _vm._l(child.terminalList, function (terminal, tIndex) {
                  return _c(
                    "div",
                    {
                      key: "terminal" + tIndex,
                      staticClass: "flex",
                      staticStyle: { "justify-content": "space-between" },
                    },
                    [
                      _c("RuleRender", {
                        staticClass: "render",
                        attrs: { ladder: terminal, notView: !_vm.notView },
                      }),
                      !_vm.notView
                        ? _c("a", {
                            staticClass: "el-icon-remove rowLineIcon closeIcon",
                            on: {
                              click: function () {
                                return _vm.delTerminalLadder(
                                  _vm.rowIndex,
                                  index,
                                  cIndex,
                                  tIndex
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                !_vm.notView
                  ? _c(
                      "div",
                      { staticStyle: { "padding-left": "15px" } },
                      [
                        item.participatorFlag !== "1"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "rowLineBtn",
                                attrs: { icon: "el-icon-plus" },
                                on: {
                                  click: function () {
                                    return _vm.addDealerLadder(
                                      _vm.rowIndex,
                                      index,
                                      cIndex
                                    )
                                  },
                                },
                              },
                              [_vm._v("经销商返利")]
                            )
                          : _vm._e(),
                        item.participatorFlag === "3"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "rowLineBtn",
                                attrs: { icon: "el-icon-plus" },
                                on: {
                                  click: function () {
                                    return _vm.addTerminalLadder(
                                      _vm.rowIndex,
                                      index,
                                      cIndex
                                    )
                                  },
                                },
                              },
                              [_vm._v("终端返利")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            )
          }),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }