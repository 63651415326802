<!--
 * @Autor: yzr
 * @Date: 2021-08-04 10:03:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-03 14:22:41
 * @Description: 包量政策
-->
<template>
  <div>
    <!-- 产品维度 -->
    <div class="flex">
      <div style="display: flex">
        <span>包量产品维度：</span>
        <el-select v-model="productLevel" :disabled="notView" @change="clearSelectProduct" style="width:100px">
          <el-option v-for="item in productLevelList" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode">
          </el-option>
        </el-select>
      </div>
      <el-button v-if="!notView" type="primary" icon="el-icon-plus" @click="addRow">添加政策</el-button>
    </div>

    <!-- 添加政策 -->
    <div style="margin: 20px 0">
      <div :style="{ maxHeight: maxTableHeight, overflowY: 'auto' }">
        <div class="rowSection" v-for="(tableItem, index) in tableData" :key="index">
          <!-- 政策规则配置 -->
          <div style="display:flex;justify-content: space-between;">
            <span>政策规则配置：</span>
            <a :disabled="notView" style="line-height: 36px;margin-right:20px;" class="el-icon-circle-plus-outline" @click="() => addConfig(index)">
              添加阶梯
            </a>
          </div>
          <vxe-table border ref="xTable" show-header-overflow show-overflow highlight-hover-row :data="tableItem.data || tableInfo.data" show-footer :edit-config="{ trigger: 'click', mode: 'row' }">
            <vxe-table-column width="50px" title="操作">
              <template>
                <el-link :disabled='notView' :underline="false" @click="() => delLadeer(index, lIndex)">
                  删除
                </el-link>
              </template>
            </vxe-table-column>
            <vxe-table-column v-for="(col, lIndex) in tableInfo.columns" :key="lIndex" :field="col.field" :title="col.title" :edit-render="
                col.editRender && {
                  ...col.editRender,
                  props: {
                    ...col.editRender.props,
                    disabled: tableDisable(notView, col.field)
                  }
                }
              " :min-width="col.width ? col.width : 80" :fixed="col.fixed">
            </vxe-table-column>
          </vxe-table>

          <!-- 产品 -->
          <a v-if="!notView" class="el-icon-close close" @click="() => delRow(index)"></a>
          <div style="background: #f9f9f9;padding: 0 20px;margin:10px 0">
            <div class="flex">
              <span>产品：</span>
              <a v-if="!notView && productLevel !== 'all'" style="line-height: 36px" class="el-icon-circle-plus-outline" @click="() => addProduct(index)">添加产品</a>
            </div>
            <el-tag v-for="tag in tableItem.quantifyRanges" :key="tag.specialCode" :closable="!notView" style="margin-right: 5px" effect="plain" @close="() => closeTag(tag, index)">
              {{ tag.specialName }}
            </el-tag>
          </div>

          <!-- 政策奖励配置： -->
          <div style="background: #f9f9f9;padding:20px; margin-top: 10px">
            <div class="titleflex">
              <div>
                <!-- <el-radio v-model="tableItem.awardType" label="Y" border size="mini">按最高达成奖励</el-radio>
                <el-radio v-model="tableItem.awardType" label="N" border size="mini">每次达成奖励</el-radio> -->
              </div>
              <!-- <div>
                <a style="line-height: 36px" class="el-icon-circle-plus-outline" @click="() => addbili(index)">添加比例</a>
              </div> -->
            </div>
            <table>
              <tr>
                <td>操作</td>
                <td>阶梯达成比例（%）</td>
                <td>奖励类型</td>
                <td>奖励名称</td>
                <td>奖励周期（可多选）</td>
              </tr>
              <tr v-for="(item, rIndex) in tableItem.quantifyExpressions" :key="rIndex">
                <td>
                  <el-button :disabled='notView' @click="() => deleteRow(index)">删除</el-button>
                </td>
                <td style="centerDisplay">
                  <el-input-number :controls="false" placeholder="请输入阶梯达成比例" :disabled="notView" v-model="item.reachRate">
                  </el-input-number>
                </td>
                <td style="centerDisplay">
                  <el-select :disabled="notView" @change="val => rewardChange(val, rIndex, index)" class="tableItem" v-model="item.rewardMethodFlag" style="margin-bottom:0;display:inline-block;width:100px;">
                    <el-option v-for="option in rewardList" :key="option.key" :value="option.key" :label="option.name"></el-option>
                  </el-select>

                  <div style="display:inline-block;">
                    <el-link @click="() => addInKind(index, rIndex)" :disabled="notView" v-if="item.rewardMethodFlag === 'gift'">添加实物奖励</el-link>
                  </div>
                </td>
                <td style="width:100px">
                  <span v-if="item.rewardMethodFlag === 'gift'">{{item.rewardName}}</span>
                  <span v-if="item.rewardMethodFlag === 'cash'">现金奖励</span>
                </td>
                <td>
                  <el-select :disabled='notView' v-model="item.tagArr" multiple @change="(val)=>changTag(val,index,rIndex)" placeholder="请选择">
                    <el-option v-for="item in item.selectArr" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </td>
                <template v-for="(num, jIndex) in item.textArr">
                  <td v-if="item.rewardMethodFlag === 'gift'"  :key="jIndex">
                    <el-input :disabled='notView' v-model.number="num.number" @change="(val) => formatterNum(val, num)" :placeholder="num.value + '度达成奖励数量(瓶)'"></el-input>
                  </td>
                </template>
              </tr>

            </table>
            <table style="margin-top:30px"
               v-if="tableItem.quantifyMaterials.length>0">
                  <tr>
                    <td>产品名称</td>
                    <td>产品编码</td>
                    <td v-for="(dh, dhIndex) in tableItem.dynamicHead" :key="dhIndex">
                        {{dh.value}}现金奖励(元/箱)
                    </td>

                  </tr>
                  <tr v-for="(cpItem, cpIndex) in tableItem.quantifyMaterials" :key="cpIndex">
                    <td>
                      <span>{{cpItem.materialName}}</span>
                    </td>
                    <td style="centerDisplay">
                      <span>{{cpItem.materialCode}}</span>
                    </td>

                    <td v-for="(st, stIndex) in cpItem.selectArray" :key="stIndex">
                      <el-input :disabled='notView'  v-model.number="st.number" :placeholder="st.value + '现金奖励'"></el-input>
                    </td>
                  </tr>
                </table>
          </div>
        </div>
      </div>
    </div>

    <SelectConfig ref="productRef" @onGetSelect="productConfirm" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

const selectArr = {
  month: {
    dictCode: 'month',
    value: '月',
    number: '',
    label: '按月返',
  },
  season: {
    dictCode: 'season',
    value: '季',
    number: '',
    label: '按季返',
  },
  halfYear: {
    dictCode: 'halfYear',
    value: '半年',
    number: '',
    label: '按半年返',
  },
  year: {
    dictCode: 'year',
    value: '年',
    number: '',
    label: '按年返',
  },
};

export default {
  name: 'packege-num',
  components: {
    SelectConfig,
  },
  props: {
    targetType: String,
    edit: String,
    // tableData: Array,
    notView: Boolean,
    weekOptions: Array,
    productLevelList: Array,
    // rewardList: Array,
  },
  created() {
    // 确保表格占满全屏
    this.maxTableHeight = `${document.querySelector('.drawer-container')
      .clientHeight
      - 30
      - 48}px`;
    // this.getConfiguration();
  },
  data() {
    return {
      tagArr: [],
      calculateCycle: '',
      maxTableHeight: '500px',
      productLevel: 'materialGroup',
      quantifyRanges: [],
      tableInfo: {
        columns: [
          {
            field: 'ladderCode',
            title: '协议阶梯编码',
            width: '100',
          },
          {
            field: 'ladderType',
            title: '协议阶梯类型',
            width: '100',
          },
          {
            field: 'ladderName',
            title: '协议阶梯名称',
            width: '100',
          },
          {
            field: 'yearSaleNum',
            title: '总销量',
          },
          {
            field: 'januarySaleNum',
            title: '一月',
          },
          {
            field: 'februarySaleNum',
            title: '二月',
          },
          {
            field: 'marchSaleNum',
            title: '三月',
          },
          {
            field: 'aprilSaleNum',
            title: '四月',
          },
          {
            field: 'maySaleNum',
            title: '五月',
          },
          {
            field: 'juneSaleNum',
            title: '六月',
          },
          {
            field: 'julySaleNum',
            title: '七月',
          },
          {
            field: 'augustSaleNum',
            title: '八月',
          },
          {
            field: 'septemberSaleNum',
            title: '九月',
          },
          {
            field: 'octoberSaleNum',
            title: '十月',
          },
          {
            field: 'novemberSaleNum',
            title: '十一月',
          },
          {
            field: 'decemberSaleNum',
            title: '十二月',
          },
        ],
        data: [],
      },
      tableData: [],
      recieveData: [],
      delDisable: false,
      channelList: [],
      rewardList: [
        {
          name: '赠品',
          key: 'gift',
          flag: '4',
          rewardComputeTypeMountVos: [],
        },
        {
          name: '现金',
          key: 'cash',
          flag: '5',
          rewardComputeTypeMountVos: [],
        },
      ],
      // myRewardMethodFlag: 'gift',
    };
  },
  watch: {
    async recieveData(v) {
      this.calculateCycle = v.calculateCycle;
      if (v.quantifyConfigurations.length) {
        this.productLevel = v.dimensionName;
      }
      this.tableData = v.quantifyConfigurations;
      this.tableData[0].awardType = this.tableData[0].onlyHighestLevel;
      let tempRes = await this.spiltArrayBykey(
        v.quantifyConfigurations[0].quantifyExpressions,
        'specialCode',
      );
      tempRes = tempRes.map((item) => this.assembleObj(
        item,
        ['calculateCycle', 'rewardData'],
        ['calculateCycle', 'rewardData'],
        'textArr',
      ));
      const tempArr = [];
      this.tableData[0].quantifyExpressions = tempRes;
      /* eslint-disable-next-line */
      this.tableData.forEach((tb) => {
        // 分组
        const res = this.groupByCode(tb.quantifyMaterials, 'materialCode');
        let tempHead = [];
        res.forEach((item) => {
          const selectArray = [];
          let mItem = {};
          item.data.forEach((qm) => {
            mItem = qm;
            if (qm.taskType === 'season') {
              selectArray.push({
                dictCode: 'season',
                value: '季',
                number: qm.rewardData,
                label: '按季返',
              });
            } else if (qm.taskType === 'halfYear') {
              selectArray.push({
                number: qm.rewardData,
                dictCode: 'halfYear',
                value: '半年',
                label: '按半年返',
              });
            } else if (qm.taskType === 'year') {
              selectArray.push({
                number: qm.rewardData,
                dictCode: 'halfYear',
                value: '年',
                label: '按年返',
              });
            }
          });
          tempArr.push({
            ...mItem,
            selectArray,
          });
          tempHead = selectArray;
        });
        /* eslint-disable-next-line */
        tb.dynamicHead = tempHead;
        /* eslint-disable-next-line */
        tb.quantifyMaterials = tempArr;
        this.$forceUpdate();
      });
      this.getLadderData(this.tableData[0].agreementLadderCode);
    },
  },
  create() {
    this.getDict();
  },
  methods: {
    // 分组
    groupByCode(arr, param) {
      const map = {};
      const dest = [];
      for (let i = 0; i < arr.length; i += 1) {
        const ai = arr[i];
        if (ai[param] && !map[ai[param]]) {
          dest.push({
            name: ai[param],
            data: [ai],
          });
          map[ai[param]] = ai;
        } else {
          for (let j = 0; j < dest.length; j += 1) {
            const dj = dest[j];
            if (dj.name === ai[param]) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      return dest;
    },
    // 组装详情数据
    async spiltArrayBykey(arr, key) {
      const target = [];
      const keyMap = {};
      const dataArr = arr.map(async (val) => {
        const item = val;
        if (item.giftCode !== '') {
          item.rewardName = await this.getRaward(item.giftCode);
        }
        return item;
      });
      const res = await Promise.all(dataArr);
      res.forEach((item) => {
        const value = item[key];
        if (typeof keyMap[value] !== 'undefined') {
          target[keyMap[value]].push(item);
        } else {
          target.push([item]);
          keyMap[value] = target.length - 1;
        }
      });
      return target;
    },
    combine2Obj(obj, keys) {
      const target = {};
      for (const key of keys) {
        target[key] = obj[key];
      }
      return {
        ...selectArr[target.calculateCycle],
        number: target.rewardData,
      };
    },
    assembleObj(arr, keys, assembleKeys, assembleResultKey) {
      if (!(arr instanceof Array) || arr.length === 0) {
        return false;
      }
      const target = {
        ...arr[0],
      };
      if (assembleKeys && assembleResultKey) {
        target[assembleResultKey] = [];
      }
      arr.forEach((item) => {
        for (const key of keys) {
          if (typeof target[key] === 'object') {
            target[key].push(item[key]);
          } else {
            target[key] = [item[key]];
          }
        }
        if (assembleKeys && assembleResultKey) {
          const temp = this.combine2Obj(item, assembleKeys);
          target[assembleResultKey].push(temp);
          if (target.tagArr) {
            target.tagArr.push(temp.label);
          } else {
            target.tagArr = [temp.label];
          }
        }
      });
      return target;
    },
    // 获取阶梯数据
    getLadderData(code) {
      request
        .get('/cps/v1/agreement/agreementLadder/findByLadderCode', {
          ladderCode: code,
        })
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          this.tableInfo.data.push(res.result);
          this.tableInfo.data.map((value) => {
            const v = value;
            if (v.ladderType === 'quantify') {
              v.ladderType = '包量';
            }
            return v;
          });
        });
    },
    // 获取奖励
    async getRaward(code) {
      const params = { productLevelType: 'inKind', materialCode: code };
      const res = await request.post('/mdm/mdmMaterialController/pageList', params);
      if (!res.success) return this.$message.error(res.message);
      return res.result.data[0].materialName;
    },

    // 获取数据字典
    getDict() {
      // 渠道
      request
        .post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'agreement_ladder',
        })
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          this.channelList = res.result;
        });
    },
    // 关闭标签
    async closeTag(val, index) {
      this.tableData[index].quantifyRanges = this.tableData[
        index
      ].quantifyRanges.filter((item) => item.specialCode !== val.specialCode);
      this.quantifyRanges = this.quantifyRanges.filter(
        (item) => item.specialCode !== val.specialCode,
      );
      this.tableData[index].quantifyMaterials = await this.getCashProduct(index);
      this.handleMaterialInfo(0, index);
    },

    // 添加产品
    addProduct(index) {
      let functionCode = 'treety_modules_productList';
      let idKey = 'productAndLevelCode';
      if (this.productLevel === 'material') {
        functionCode = 'treety_modules_productList_material';
        idKey = 'materialCode';
      } else if (this.productLevel === 'all') {
        functionCode = 'treety_modules_productList';
        idKey = 'productAndLevelCode';
      } else if (this.productLevel === 'materialGroup') {
        functionCode = 'treety_modules_productList_materialGroup';
        idKey = 'materialGroupCode';
      } else {
        functionCode = 'treety_modules_productList_levelType';
        idKey = 'productLevelCode';
      }

      let productLevelType = null;
      switch (this.productLevel) {
        case 'brand':
          productLevelType = 'brand';
          break;
        case 'series':
          productLevelType = 'series';
          break;
        case 'max':
          productLevelType = 'max';
          break;
        case 'min':
          productLevelType = 'min';
          break;
        default:
          productLevelType = null;
          break;
      }

      const params = {
        functionCode,
        data: this.tableData[index].quantifyRanges,
        selectionList: [],
        idKey,
        noReset: true,
        paramData: {
          productLevelType,
        },
      };
      this.modelIndex = index;
      this.clickType = 'cp';
      this.$refs.productRef.openSelectModal(params);
    },

    // 添加阶梯
    addConfig(index) {
      const params = {
        functionCode: 'treety_modules_addConfig',
        data: this.tableData[index].data,
        selectionList: [],
        idKey: 'productLevelCode',
        noReset: true,
        requestType: 'GET',
        paramData: {
          productLevelType: 'ladder',
          enableStatus: '009',
          ladderType: 'quantify',
        },
      };
      this.modelIndex = index;
      this.clickType = 'jt';
      this.$refs.productRef.openSelectModal(params);
    },
    // 删除阶梯
    delLadeer(index, lIndex) {
      this.tableData[index].data.splice(lIndex, 1);
    },

    clearSelectProduct(val) {
      this.quantifyRanges = [];
      this.tableData.forEach((item) => {
        const data = item;
        if (val === 'all') {
          data.dimensionFlag = 'DIMENSION_ALL';
          data.dimensionName = '全部产品';
        } else {
          let dimensionName = '';
          this.productLevelList.forEach((p) => {
            if (p.dictCode === this.productLevel) dimensionName = p.dictValue;
          });
          data.dimensionFlag = val;
          data.dimensionName = dimensionName;
        }
        data.quantifyRanges = [];
        return data;
      });
    },
    async productConfirm(params) {
      if (this.clickType === 'jt') { // 阶梯
        params.map((i) => {
          const item = i;
          if (item.ladderType === 'quantify') {
            item.ladderType = '包量';
          }
          return item;
        });
        this.tableData[this.modelIndex].data = params;
      }
      if (this.clickType === 'sj') {
        this.tableData[this.modelIndex].quantifyExpressions[this.level].giftCode = params[0].materialCode;
        this.tableData[this.modelIndex].quantifyExpressions[this.level].rewardName = params[0].materialName;
      }
      if (this.clickType === 'cp') {
        const quantifyRanges = [];
        this.quantifyRanges = [];
        params.forEach((item) => {
          if (this.productLevel === 'all') {
            quantifyRanges.push({
              ...item,
              specialName: item.productAndLevelName,
              specialCode: item.productAndLevelCode,
            });
          } else if (this.productLevel === 'material') {
            quantifyRanges.push({
              ...item,
              specialName: item.materialName,
              specialCode: item.materialCode,
            });
          } else if (this.productLevel === 'materialGroup') {
            quantifyRanges.push({
              ...item,
              specialName: item.materialGroupName,
              specialCode: item.materialGroupCode,
            });
          } else {
            quantifyRanges.push({
              ...item,
              specialName: item.productLevelName,
              specialCode: item.productLevelCode,
            });
          }
        });
        this.tableData[this.modelIndex].quantifyRanges = quantifyRanges;
        this.quantifyRanges = quantifyRanges;
        if (this.tableData[this.modelIndex].quantifyExpressions && this.tableData[this.modelIndex].quantifyExpressions[0]
        && this.tableData[this.modelIndex].quantifyExpressions[0].rewardMethodFlag === 'cash') {
          this.tableData[this.modelIndex].quantifyMaterials = await this.getCashProduct(this.modelIndex);
          this.handleMaterialInfo(0, this.modelIndex);
        }
      }
    },

    formatterNum(val, row) {
      if (row.rewardMethodFlag === 'integral') {
        this.$set(row, 'rewardData', Number(val).toFixed(0));
      } else {
        this.$set(row, 'rewardData', Number(val).toFixed(2));
      }
    },

    tableDisable(edit, field) {
      if (edit) {
        return true;
      }
      if (field === 'productName' || field === 'productLevelName') {
        return true;
      }

      return false;
    },
    // 总销量输入事件
    targetNumInput(val) {
      // 输入总销量 平分至12个月 不能整除则累加至第12月
      const monthTarget = Math.floor(Number(val.row.totalSale) / 12);
      this.$set(val.row, 'januarySaleNum', monthTarget);
      this.$set(val.row, 'februarySaleNum', monthTarget);
      this.$set(val.row, 'marchSaleNum', monthTarget);
      this.$set(val.row, 'aprilSaleNum', monthTarget);
      this.$set(val.row, 'maySaleNum', monthTarget);
      this.$set(val.row, 'juneSaleNum', monthTarget);
      this.$set(val.row, 'julySaleNum', monthTarget);
      this.$set(val.row, 'augustSaleNum', monthTarget);
      this.$set(val.row, 'septemberSaleNum', monthTarget);
      this.$set(val.row, 'octoberSaleNum', monthTarget);
      this.$set(val.row, 'novemberSaleNum', monthTarget);
      this.$set(
        val.row,
        'decemberSaleNum',
        monthTarget + (Number(val.row.totalSale) - monthTarget * 12),
      );
    },

    // 单月销量输入事件
    monthInput(val) {
      // 单月输入 累加至总销量
      let total = 0;
      for (const i in val.row) {
        if (
          Object.prototype.hasOwnProperty.call(val.row, i)
          && i !== '_XID'
          && i !== 'totalSale'
        ) {
          total += Number(val.row[i]);
        }
      }
      this.$set(val.row, 'totalSale', total);
    },

    addRow() {
      if (this.tableData.length > 0) return;
      let dimensionName = '';
      this.productLevelList.forEach((item) => {
        if (item.dictCode === this.productLevel) dimensionName = item.dictValue;
      });

      this.tableData.push({
        dimensionFlag:
          this.productLevel === 'all' ? 'DIMENSION_ALL' : this.productLevel,
        dimensionName: this.productLevel === 'all' ? '全部产品' : dimensionName,
        policyKey: 'policyCode',
        quantifyRanges: [],
        data: [],
        awardType: 'Y',
        quantifyMaterials: [], // 选择现金时的物料信息
        dynamicHead: [], // 动态表头
        quantifyExpressions: [
          {
            reachRate: '100', // 阶梯达成比例
            rewardMethodFlag: 'gift', // 奖励方式
            rewardMethodName: '赠品',
            rewardData: '', // 奖励数据
            awardConditionFlag: 'fixedAmount',
            awardConditionName: '固定额度',
            calculateCycle: '', // 包量达成计算周期(月,季度，半年，年)
            rewardName: '', // 奖励名称
            giftCode: '',
            textArr: [],
            tagArr: [],
            selectArr: [
              // {
              //   dictCode: 'month',
              //   value: '月',
              //   number: '',
              //   label: '按月返',
              // },
              {
                dictCode: 'season',
                value: '季',
                number: '',
                label: '按季返',
              },
              {
                dictCode: 'halfYear',
                value: '半年',
                number: '',
                label: '按半年返',
              },
              {
                dictCode: 'year',
                value: '年',
                number: '',
                label: '按年返',
              },
            ],
          },
        ],
      });
    },
    delRow(index) {
      this.tableData.splice(index, 1);
    },
    // 更改奖励类型
    async rewardChange(val, rIndex, index) {
      let rewardMethodName = '';
      this.rewardList.forEach((item) => {
        if (item.key === val) {
          rewardMethodName = item.name;
        }
      });
      this.tableData[index].quantifyExpressions[rIndex].rewardMethodName = rewardMethodName;
      this.tableData[index].quantifyExpressions[rIndex].rewardMethodFlag = val;
      if (val === 'cash') { // 现金
        this.tableData[index].quantifyMaterials = await this.getCashProduct(index);
        this.tableData[index].quantifyExpressions[rIndex].rewardName = null;
        this.tableData[index].quantifyExpressions[rIndex].giftCode = null;
        this.handleMaterialInfo(rIndex, index);
      } else { // 赠品
        this.tableData[index].quantifyMaterials = [];
      }
    },
    // 处理物料数据
    handleMaterialInfo(rIndex, index) {
      const tagArr = this.tableData[index].quantifyExpressions[rIndex].tagArr || [];
      const selectArray = this.tableData[index].quantifyExpressions[rIndex].selectArr || [];
      const tempArr = [];
      tagArr.forEach((tg) => {
        selectArray.forEach((sa) => {
          if (sa.value === tg) {
            tempArr.push(sa);
          }
        });
      });

      this.tableData[index].dynamicHead = tempArr;
      this.tableData[index].quantifyMaterials.forEach((qm, qmIndex) => {
        this.$set(this.tableData[index].quantifyMaterials, qmIndex, { ...qm, selectArray: JSON.parse(JSON.stringify(tempArr)) });
      });
    },
    // 获取选择现金时的物料组信息
    async getCashProduct(index) {
      if (this.tableData[index].quantifyRanges && this.tableData[index].quantifyRanges.length > 0) {
        const code = this.tableData[index].quantifyRanges.map((item) => item.specialCode);
        const res = await request.post('/mdm/mdmMaterialExtController/findMaterialListByGroupCodes', code);
        return res.result || [];
      }
      return [];
    },
    // 添加比例
    addbili(index) {
      this.tableData[index].quantifyExpressions.push({
        reachRate: '100', // 阶梯达成比例
        rewardMethodFlag: 'gift', // 奖励方式
        rewardMethodName: '赠品',
        rewardData: '', // 奖励数据
        awardConditionFlag: 'fixedAmount',
        awardConditionName: '固定额度',
        calculateCycle: '', // 包量达成计算周期(月,季度，半年，年)
        rewardName: '', // 奖励名称
        giftCode: '', // 赠品编码
        textArr: [],
        tagArr: [],
        selectArr: [
          // {
          //   dictCode: 'month',
          //   value: '月',
          //   number: '',
          //   label: '按月返',
          // },
          {
            dictCode: 'season',
            value: '季',
            number: '',
            label: '按季返',
          },
          {
            dictCode: 'halfYear',
            value: '半年',
            number: '',
            label: '按半年返',
          },
          {
            dictCode: 'year',
            value: '年',
            number: '',
            label: '按年返',
          },
        ],
      });
    },
    // deleteTag(index, rIndex, tIndex) {
    //   if (
    //     this.tableData[index].quantifyExpressions[rIndex].textArr.length > 1
    //   ) {
    //     this.tableData[index].quantifyExpressions[rIndex].textArr.splice(
    //       tIndex,
    //       1,
    //     );
    //   }
    // },
    // 添加实物奖励
    addInKind(index, rIndex) {
      const params = {
        functionCode: 'treety_modules_addInKind',
        // data: this.tableData[index].quantifyExpressions[rIndex].rewardName,
        data: [],
        selectionList: [],
        idKey: 'id',
        noReset: true,
        paramData: {
          productLevelType: 'inKind',
        },
      };
      this.modelIndex = index;
      this.level = rIndex;
      this.clickType = 'sj';
      this.$refs.productRef.openSelectModal(params);
    },
    // 删除行
    deleteRow(index, rIndex) {
      if (this.tableData[index].quantifyExpressions.length > 1) {
        this.tableData[index].quantifyExpressions.splice(rIndex, 1);
      }
    },
    // 最高达成奖励
    highest(index) {
      this.tableData[index].awardType = 'Y';
    },
    // 每次达成奖励
    everyTime(index) {
      this.tableData[index].awardType = 'N';
    },

    // 下拉框值发生改变触发事件
    changTag(val, index, rIndex) {
      let data = [];
      data = val;
      this.tableData[index].quantifyExpressions[rIndex].textArr = [];
      data.forEach((v, i) => {
        if (v === '月') {
          this.tableData[index].quantifyExpressions[rIndex].textArr.push({
            dictCode: 'month',
            value: '月',
            number: '',
            label: '按月返',
          });
        } else if (v === '季') {
          this.tableData[index].quantifyExpressions[rIndex].textArr.push({
            dictCode: 'season',
            value: '季',
            number: '',
            label: '按季返',
          });
        } else if (v === '半年') {
          this.tableData[index].quantifyExpressions[rIndex].textArr.push({
            dictCode: 'halfYear',
            value: '半年',
            number: '',
            label: '按半年返',
          });
        } else if (v === '年') {
          this.tableData[index].quantifyExpressions[rIndex].textArr.push({
            dictCode: 'year',
            value: '年',
            number: '',
            label: '按年返',
          });
        }
      });
      this.handleMaterialInfo(rIndex, index);
    },
  },
};
</script>

<style lang="less" scoped>
.weekly {
  display: flex;
  margin: 10px 0;
}

.el-input {
  display: flex;
  .el-input-group__append {
    height: 32px;
  }
  .apend {
    display: inline-block;
    // height: 30px;
    line-height: 30px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}
.centerDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleflex {
  display: flex;
  justify-content: space-between;
}
table,
tr,
td {
  border: 1px solid #e8eaec;
  text-align: center;
}
td {
  padding: 0 2px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
.iconpostion {
  position: relative;
  top: -5px;
  right: 10px;
  font-weight: 700;
}

.titleposition {
  display: flex;
  span {
    flex: 1;
  }
}
.contentflex {
  display: flex;
  justify-content: space-around;

  div {
    flex: 1;
  }
}

.rowLineIcon {
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
}
.closeIcon {
  color: #f56c6c !important;
}

.rowSection {
  position: relative;
  border: 1px solid #d3d5d8;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0;
}

.close {
  display: inline-block;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45) !important;
  position: absolute;
  right: 2px;
  top: 2px;

  &:hover {
    color: rgba(0, 0, 0, 0.8) !important;
  }
}

.tableItem {
  width: 200px;
  margin-bottom: 10px;
}

.requer {
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
</style>
