var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("span", [_vm._v("包量产品维度：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px" },
                  attrs: { disabled: _vm.notView },
                  on: { change: _vm.clearSelectProduct },
                  model: {
                    value: _vm.productLevel,
                    callback: function ($$v) {
                      _vm.productLevel = $$v
                    },
                    expression: "productLevel",
                  },
                },
                _vm._l(_vm.productLevelList, function (item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          !_vm.notView
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addRow },
                },
                [_vm._v("添加政策")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticStyle: { margin: "20px 0" } }, [
        _c(
          "div",
          { style: { maxHeight: _vm.maxTableHeight, overflowY: "auto" } },
          _vm._l(_vm.tableData, function (tableItem, index) {
            return _c(
              "div",
              { key: index, staticClass: "rowSection" },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("span", [_vm._v("政策规则配置：")]),
                    _c(
                      "a",
                      {
                        staticClass: "el-icon-circle-plus-outline",
                        staticStyle: {
                          "line-height": "36px",
                          "margin-right": "20px",
                        },
                        attrs: { disabled: _vm.notView },
                        on: {
                          click: function () {
                            return _vm.addConfig(index)
                          },
                        },
                      },
                      [_vm._v(" 添加阶梯 ")]
                    ),
                  ]
                ),
                _c(
                  "vxe-table",
                  {
                    ref: "xTable",
                    refInFor: true,
                    attrs: {
                      border: "",
                      "show-header-overflow": "",
                      "show-overflow": "",
                      "highlight-hover-row": "",
                      data: tableItem.data || _vm.tableInfo.data,
                      "show-footer": "",
                      "edit-config": { trigger: "click", mode: "row" },
                    },
                  },
                  [
                    _c(
                      "vxe-table-column",
                      { attrs: { width: "50px", title: "操作" } },
                      [
                        [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                disabled: _vm.notView,
                                underline: false,
                              },
                              on: {
                                click: function () {
                                  return _vm.delLadeer(index, _vm.lIndex)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                      ],
                      2
                    ),
                    _vm._l(_vm.tableInfo.columns, function (col, lIndex) {
                      return _c("vxe-table-column", {
                        key: lIndex,
                        attrs: {
                          field: col.field,
                          title: col.title,
                          "edit-render":
                            col.editRender &&
                            Object.assign({}, col.editRender, {
                              props: Object.assign({}, col.editRender.props, {
                                disabled: _vm.tableDisable(
                                  _vm.notView,
                                  col.field
                                ),
                              }),
                            }),
                          "min-width": col.width ? col.width : 80,
                          fixed: col.fixed,
                        },
                      })
                    }),
                  ],
                  2
                ),
                !_vm.notView
                  ? _c("a", {
                      staticClass: "el-icon-close close",
                      on: {
                        click: function () {
                          return _vm.delRow(index)
                        },
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: {
                      background: "#f9f9f9",
                      padding: "0 20px",
                      margin: "10px 0",
                    },
                  },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c("span", [_vm._v("产品：")]),
                      !_vm.notView && _vm.productLevel !== "all"
                        ? _c(
                            "a",
                            {
                              staticClass: "el-icon-circle-plus-outline",
                              staticStyle: { "line-height": "36px" },
                              on: {
                                click: function () {
                                  return _vm.addProduct(index)
                                },
                              },
                            },
                            [_vm._v("添加产品")]
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(tableItem.quantifyRanges, function (tag) {
                      return _c(
                        "el-tag",
                        {
                          key: tag.specialCode,
                          staticStyle: { "margin-right": "5px" },
                          attrs: { closable: !_vm.notView, effect: "plain" },
                          on: {
                            close: function () {
                              return _vm.closeTag(tag, index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(tag.specialName) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      background: "#f9f9f9",
                      padding: "20px",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _vm._m(0, true),
                    _c(
                      "table",
                      [
                        _vm._m(1, true),
                        _vm._l(
                          tableItem.quantifyExpressions,
                          function (item, rIndex) {
                            return _c(
                              "tr",
                              { key: rIndex },
                              [
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { disabled: _vm.notView },
                                        on: {
                                          click: function () {
                                            return _vm.deleteRow(index)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticStyle: {} },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        controls: false,
                                        placeholder: "请输入阶梯达成比例",
                                        disabled: _vm.notView,
                                      },
                                      model: {
                                        value: item.reachRate,
                                        callback: function ($$v) {
                                          _vm.$set(item, "reachRate", $$v)
                                        },
                                        expression: "item.reachRate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticStyle: {} },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "tableItem",
                                        staticStyle: {
                                          "margin-bottom": "0",
                                          display: "inline-block",
                                          width: "100px",
                                        },
                                        attrs: { disabled: _vm.notView },
                                        on: {
                                          change: function (val) {
                                            return _vm.rewardChange(
                                              val,
                                              rIndex,
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.rewardMethodFlag,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "rewardMethodFlag",
                                              $$v
                                            )
                                          },
                                          expression: "item.rewardMethodFlag",
                                        },
                                      },
                                      _vm._l(_vm.rewardList, function (option) {
                                        return _c("el-option", {
                                          key: option.key,
                                          attrs: {
                                            value: option.key,
                                            label: option.name,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        item.rewardMethodFlag === "gift"
                                          ? _c(
                                              "el-link",
                                              {
                                                attrs: {
                                                  disabled: _vm.notView,
                                                },
                                                on: {
                                                  click: function () {
                                                    return _vm.addInKind(
                                                      index,
                                                      rIndex
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("添加实物奖励")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("td", { staticStyle: { width: "100px" } }, [
                                  item.rewardMethodFlag === "gift"
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.rewardName)),
                                      ])
                                    : _vm._e(),
                                  item.rewardMethodFlag === "cash"
                                    ? _c("span", [_vm._v("现金奖励")])
                                    : _vm._e(),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled: _vm.notView,
                                          multiple: "",
                                          placeholder: "请选择",
                                        },
                                        on: {
                                          change: function (val) {
                                            return _vm.changTag(
                                              val,
                                              index,
                                              rIndex
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.tagArr,
                                          callback: function ($$v) {
                                            _vm.$set(item, "tagArr", $$v)
                                          },
                                          expression: "item.tagArr",
                                        },
                                      },
                                      _vm._l(item.selectArr, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(item.textArr, function (num, jIndex) {
                                  return [
                                    item.rewardMethodFlag === "gift"
                                      ? _c(
                                          "td",
                                          { key: jIndex },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.notView,
                                                placeholder:
                                                  num.value +
                                                  "度达成奖励数量(瓶)",
                                              },
                                              on: {
                                                change: function (val) {
                                                  return _vm.formatterNum(
                                                    val,
                                                    num
                                                  )
                                                },
                                              },
                                              model: {
                                                value: num.number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    num,
                                                    "number",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "num.number",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    tableItem.quantifyMaterials.length > 0
                      ? _c(
                          "table",
                          { staticStyle: { "margin-top": "30px" } },
                          [
                            _c(
                              "tr",
                              [
                                _c("td", [_vm._v("产品名称")]),
                                _c("td", [_vm._v("产品编码")]),
                                _vm._l(
                                  tableItem.dynamicHead,
                                  function (dh, dhIndex) {
                                    return _c("td", { key: dhIndex }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(dh.value) +
                                          "现金奖励(元/箱) "
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._l(
                              tableItem.quantifyMaterials,
                              function (cpItem, cpIndex) {
                                return _c(
                                  "tr",
                                  { key: cpIndex },
                                  [
                                    _c("td", [
                                      _c("span", [
                                        _vm._v(_vm._s(cpItem.materialName)),
                                      ]),
                                    ]),
                                    _c("td", { staticStyle: {} }, [
                                      _c("span", [
                                        _vm._v(_vm._s(cpItem.materialCode)),
                                      ]),
                                    ]),
                                    _vm._l(
                                      cpItem.selectArray,
                                      function (st, stIndex) {
                                        return _c(
                                          "td",
                                          { key: stIndex },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.notView,
                                                placeholder:
                                                  st.value + "现金奖励",
                                              },
                                              model: {
                                                value: st.number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    st,
                                                    "number",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "st.number",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c("SelectConfig", {
        ref: "productRef",
        on: { onGetSelect: _vm.productConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleflex" }, [_c("div")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("操作")]),
      _c("td", [_vm._v("阶梯达成比例（%）")]),
      _c("td", [_vm._v("奖励类型")]),
      _c("td", [_vm._v("奖励名称")]),
      _c("td", [_vm._v("奖励周期（可多选）")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }