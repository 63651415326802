<!--
 * @Autor: yzr
 * @Date: 2021-08-03 17:11:07
 * @LastEditors: yzr
 * @LastEditTime: 2021-08-19 09:47:59
 * @Description: 协议文本
-->
<template>
  <div>
    <div ref='editor'></div>
  </div>
</template>
<script>
import E from 'wangeditor';

export default {
  name: 'editor',
  data() {
    return {
      currentEditor: null,
      receiveTxt: '',
    };
  },
  watch: {
    receiveTxt(v) {
      this.currentEditor.txt.html(v);
      // this.currentEditor.txt.append('<div class="signContenner"><div class="firstSigher"><p>甲方：</p></div><div class="secondSigner"><p>乙方：</p></div><div class="thirdSigner"><p>丙方：</p></div><p class="signDate">日期：</p></div>');
      this.currentEditor.disable();
    },
  },
  mounted() {
    const editor = new E(this.$refs.editor);
    this.currentEditor = editor;
    editor.create();
  },
};

</script>
