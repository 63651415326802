var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.notView,
              expression: "!notView",
            },
          ],
          staticClass: "title",
        },
        [_vm._v("协议包含政策类型(单选)")]
      ),
      _c(
        "el-radio-group",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.notView,
              expression: "!notView",
            },
          ],
          on: { change: _vm.handleChange },
          model: {
            value: _vm.modules,
            callback: function ($$v) {
              _vm.modules = $$v
            },
            expression: "modules",
          },
        },
        _vm._l(_vm.policyList, function (policy) {
          return _c(
            "el-radio-button",
            {
              key: policy.key,
              attrs: { disabled: _vm.notView, label: policy.key },
              on: { change: _vm.closeCheckbox },
            },
            [_vm._v(_vm._s(policy.name))]
          )
        }),
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "tabs",
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.enableTabs, function (item) {
          return _c("el-tab-pane", {
            key: item.key,
            attrs: { label: item.name, name: item.key },
          })
        }),
        1
      ),
      _c("PackageNum", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeName === "quantifyPolicy",
            expression: "activeName === 'quantifyPolicy'",
          },
        ],
        ref: "PackageNum",
        attrs: {
          notView: _vm.notView,
          productLevelList: _vm.productLevelList,
          rewardList: _vm.rewardList,
          weekOptions: _vm.weekOptions,
        },
      }),
      _c("Display", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeName === "displayPolicy",
            expression: "activeName === 'displayPolicy'",
          },
        ],
        ref: "Display",
        attrs: {
          notView: _vm.notView,
          productLevelList: _vm.productLevelList,
          rewardList: _vm.rewardList.filter(function (item) {
            return item.key === "gift"
          }),
          displayTypeList: _vm.displayTypeList,
        },
      }),
      _c("ScanTable", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeName === "scanCode",
            expression: "activeName === 'scanCode'",
          },
        ],
        ref: "ScanTable",
        attrs: {
          notView: _vm.notView,
          productLevelList: _vm.productLevelList,
          rewardList: _vm.rewardList,
          barCodeList: _vm.barCodeList,
        },
        on: { changeDealer: _vm.changeDealer },
      }),
      _c("TreetyContent", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeName === "text",
            expression: "activeName === 'text'",
          },
        ],
        ref: "TreetyContent",
        attrs: { notView: _vm.notView },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }