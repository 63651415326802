<!--
 * @Autor: yzr
 * @Date: 2021-07-22 15:37:15
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-13 11:22:45
 * @Description: 奖励政策标准
-->
<template>
  <div>
    <div class="flex standard" v-for="(item, index) in list" :key="index">
      <div class="type-name">
        <p class="name">
          {{item.participatorName}}
          <a v-if="!notView" class="el-icon-error close" @click="() => delStandard(rowIndex, index)"></a>
        </p>
      </div>
      <div style="padding: 5px; background-color: #F9F9F9">
        <div class="standard-detail" v-for="(child, cIndex) in item.scanCodeExpressions" :key="cIndex">
          <div class="flex">
            <RuleRender :ladder="child.ladder" :notView="!notView" />
            <a v-if="cIndex === 0 && !notView" class="el-icon-circle-plus rowLineIcon" @click="() => addRowStandard(rowIndex, index, cIndex,child)"></a>
            <a v-else-if="cIndex !== 0 && !notView" class="el-icon-remove rowLineIcon closeIcon" @click="() => delRowStandard(rowIndex, index, cIndex)"></a>
            <div>
            </div>
          </div>
          <!-- 经销商 -->
          <div class="flex" style="justify-content: space-between;" v-for="(dealer, dIndex) in child.dealerList" :key="dIndex">
            <RuleRender class="render" :ladder="dealer" :notView="!notView" />
            <a v-if="!notView" class="el-icon-remove rowLineIcon closeIcon" @click="() => delDealerLadder(rowIndex, index, cIndex, dIndex)"></a>
          </div>
          <!-- 终端 -->
          <div class="flex" style="justify-content: space-between;" v-for="(terminal, tIndex) in child.terminalList" :key="`terminal${tIndex}`">
            <RuleRender class="render" :ladder="terminal" :notView="!notView" />
            <a v-if="!notView" class="el-icon-remove rowLineIcon closeIcon" @click="() => delTerminalLadder(rowIndex, index, cIndex, tIndex)"></a>
          </div>
          <div v-if="!notView" style="padding-left: 15px">
            <el-button v-if="item.participatorFlag !== '1'" icon="el-icon-plus" class="rowLineBtn" @click="() => addDealerLadder(rowIndex, index, cIndex)">经销商返利</el-button>
            <el-button v-if="item.participatorFlag === '3'" icon="el-icon-plus" class="rowLineBtn" @click="() => addTerminalLadder(rowIndex, index, cIndex)">终端返利</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RuleRender from './rule_render';

export default {
  name: 'RewordStandard',
  components: {
    RuleRender,
  },
  props: {
    list: Array,
    addStandard: Function,
    rowIndex: Number,
    delStandard: Function,
    addRowStandard: Function,
    delRowStandard: Function,
    addDealerLadder: Function,
    addTerminalLadder: Function,
    delDealerLadder: Function,
    delTerminalLadder: Function,
    notView: Boolean,
  },
  data() {
    return {
      value: '',
      myList: [],
    };
  },
  methods: {
    saveLadder(value) {
      // console.log(value);
    },
  },
  watch: {
    list(val) {
      // console.log(val);
      // this.myList = val;
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: flex-start;
}
.plus-icon {
  font-size: 24px;
  padding: 20px 0;
}
.select {
  padding: 16px 5px;
}
.standard {
  margin: 5px 0;
  // border: 1px solid #d3d5d8;
}
.type-name {
  position: relative;
  width: 80px;
  // 禁止子元素伸缩
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 50px;
  font-size: 16px;
  text-align: center;
  margin-right: 20px;
  // background-image: linear-gradient(#e8eaec,#e8eaec),linear-gradient(#e8eaec,#e8eaec);
  // border-right: 1px solid #d3d5d8;
}
.name {
  // font-size: 16px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #c9c9c9;
}
.standard-detail {
  padding: 5px 16px;
}
.close {
  color: #f56c6c !important;
  font-size: 20px;
  display: block;
  position: absolute;
  right: -8px;
  top: -8px;
}
.rowLineIcon {
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  padding-left: 10px;
}
.closeIcon {
  color: #f56c6c !important;
}
.rowLineBtn {
  height: 32px;
  margin: 0 5px;
  padding: 5px;
}
.render {
  border: 1px dashed #0e5dc3;
  border-radius: 5px;
  margin: 5px 20px;
  padding: 8px;
  background-color: #f5f8fc;
}
</style>
