<!--
 * @Autor: yzr
 * @Date: 2021-08-05 09:47:51
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-29 14:38:40
 * @Description: 陈列政策
-->
<template>
  <div>
    <!-- 政策规则配置 -->
    <div style="margin: 20px 0">
      <div class="flex">
        <span>政策规则配置：</span>
        <el-button type='primary' icon="el-icon-plus" @click="addConfig" :disabled="notView">添加阶梯</el-button>
      </div>
      <div class="ladderTableClass">
        <vxe-table
            border
            ref="jTable"
            show-header-overflow
            show-overflow
            highlight-hover-row
            :data="ladderArr"
            show-footer
          >
            <vxe-table-column
              v-for="(col, lIndex) in tableLadderInfo.columns"
              :key="lIndex"
              :field="col.field"
              :title="col.title"
              :min-width="col.width ? col.width : 80"
              :fixed="col.fixed"
            >
            </vxe-table-column>
            <vxe-table-column width="100px" title="操作">
              <template>
                <el-link :underline="false" @click="() => delLadeer(index, lIndex)">
                  删除
                </el-link>
              </template>
            </vxe-table-column>
          </vxe-table>
      </div>
    </div>

    <div class="weekly">
      <span>陈列类型：</span>
      <el-select v-model="displayType" :disabled='notView'>
        <el-option v-for="option in displayTypeList" :key="option.dictCode" :value="option.dictCode" :label="option.dictValue"></el-option>
      </el-select>
    </div>

    <!-- 陈列规则 -->
    <div class="content">
      <div style="display: flex; margin: 0 0 10px;" v-for="(fre, index) in uploadRules" :key="index">
        <span class="title">{{`第${index + 1}次上传时间：`}}</span>
        <el-input style="width: 200px" v-model="fre.startDay" :disabled='notView'>
          <span class="apend" slot="append">号</span>
        </el-input>
        &nbsp;至&nbsp;
        <el-input style="width: 200px" v-model="fre.endDay" :disabled='notView'>
          <span class="apend" slot="append">号</span>
        </el-input>
        <a v-if="index === 0 && !notView" class="el-icon-circle-plus-outline rowLineIcon" @click="() => addFre()"></a>
        <a v-else-if="!notView" class="el-icon-circle-close rowLineIcon closeIcon" @click="() => delFre(index)"></a>
      </div>

      <div class="weekly">
        <span style="margin-right: 18px">陈列示例图片：</span>
        <el-upload
          :headers="headerObj"
          action="/upload/uploadController/uploadOssList"
          :with-credentials="true"
          list-type="picture-card"
          :auto-upload="true"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :limit="6"
          :file-list="displaySampleGraphs"
          accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"
          :disabled='notView'
        >
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" :modal="false">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>
    </div>

    <!-- 陈列配置 -->
    <div style="margin: 20px 0">
      <div class="flex">
        <div style="display: flex">
          <span>陈列产品维度：</span>
          <el-select v-model="productLevel" :disabled='notView' @change="clearSelectProduct">
            <el-option v-for="option in productLevelList" :key="option.dictCode" :value="option.dictCode" :label="option.dictValue"></el-option>
          </el-select>
        </div>
        <el-button v-if="!notView" type='primary' icon="el-icon-plus" @click="addRow">添加政策</el-button>
      </div>

      <div >
        <div class="rowSection" v-for="(tableItem, index) in tableData" :key="index">
          <a v-if="!notView" class="el-icon-close close" @click="() => delRow(index)"></a>
          <div style="background: #f9f9f9;padding: 0 20px;">
            <div class='flex'>
              <span>产品：</span>
              <a v-if="!notView && productLevel !== 'all'" style="line-height: 36px" class="el-icon-circle-plus-outline" @click="() => addProduct(index)">添加产品</a>
            </div>
            <el-tag
              v-for="tag in tableItem.displayPolicyRanges"
              :key="tag.specialCode"
              :closable='!notView'
              style="margin-right: 5px"
              effect='plain'
              @close='() => closeTag(tag, index)'
            >
              {{tag.specialName}}
            </el-tag>
          </div>

          <div style="background: #f9f9f9;padding:20px; margin-top: 10px">
            <div class="titleflex">
              <div>
                <el-radio :disabled='notView' v-model="tableItem.awardType" label="Y" border size="mini">按最高达成奖励</el-radio>
                <!-- <el-radio :disabled='notView' v-model="tableItem.awardType" label="N" border size="mini">每次达成奖励</el-radio> -->
              </div>
              <!-- <div>
                  <a
                    :disabled="notView"
                    style="line-height: 36px"
                    class="el-icon-circle-plus-outline"
                    @click="() => addbili(index)"
                    >添加比例</a
                  >
                </div> -->
            </div>
            <table>
              <tr>
                <td>操作</td>
                <td>阶梯达成比例（%）</td>
                <td>奖励类型</td>
                <td>奖励名称</td>
                <td>奖励周期（可多选）</td>
                <td v-for="(tag,tIndex) in arrTag" :key="tIndex">{{tag}}度达成奖励数量</td>
              </tr>
              <tr v-for="(item, rIndex) in tableItem.displayPolicyExpressions" :key="rIndex">
                <td>
                  <el-button :disabled='notView' @click="() => deleteRow(index)">删除</el-button>
                </td>
                <td style="centerDisplay">
                  <el-input-number
                    :controls="false"
                    placeholder="请输入阶梯达成比例"
                    :disabled="notView"
                    v-model="item.reachRate"
                  >
                  </el-input-number>
                </td>
                <td style="centerDisplay">
                  <el-select
                    :disabled="notView"
                    @change="val => rewardChange(val, rIndex, index)"
                    class="tableItem"
                    v-model="item.rewardMethodFlag"
                    style="margin-bottom:0;display:inline-block;width:100px;"
                  >
                    <el-option
                      v-for="option in rewardList"
                      :key="option.key"
                      :value="option.key"
                      :label="option.name"
                    ></el-option>
                  </el-select>
                  <div>
                    <el-link :disabled="notView" @click="() => addInKind(index, rIndex)" v-if="item.rewardMethodFlag === 'gift'">添加实物奖励</el-link>
                  </div>
                </td>
                <td style="width:100px">
                  <span v-if="item.rewardMethodFlag === 'redpacket'">{{item.rewardName =  '红包奖励'}}</span>
                  <span v-else-if="item.rewardMethodFlag === 'cost'">{{item.rewardName =  '费用奖励'}}</span>
                  <span v-else-if="item.rewardMethodFlag === 'integral'">{{item.rewardName =  '积分奖励'}}</span>
                  <span v-else-if="item.rewardMethodFlag === 'cash'">{{item.rewardName =  '现金奖励'}}</span>
                  <span v-else>{{item.rewardName}}</span>
                </td>
                <td>
                  <el-select :disabled='notView' v-model="item.tagArr" multiple @change="(val)=>changTag(val,index,rIndex)" @remove-tag="(val)=>removeTag(val,index,rIndex)" placeholder="请选择">
                    <el-option
                      v-for="item in item.selectArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </td>
                <td v-for="(num, jIndex) in item.textArr" :key="jIndex" >
                  <el-input :disabled='notView' v-model.number="num.number" @change="(val) => formatterNum(val, num)" :placeholder="num.value + '度达成奖励' + (item.rewardMethodFlag === 'gift'? '数量':'现金')"></el-input>
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>

    <!-- 销量目标 -->
    <div class="weekly">
      <span>绑定销量目标：</span>
      <el-radio-group v-model="bindSaleStatus" :disabled='notView'>
        <el-radio label="0">是</el-radio>
        <el-radio label="1">否</el-radio>
      </el-radio-group>
    </div>

    <vxe-table
      v-if="bindSaleStatus === '0'"
      border
      ref="xTable"
      show-header-overflow
      show-overflow
      highlight-hover-row
      :data="tableInfo.data"
      show-footer
      :edit-config="{ trigger: 'click', mode: 'row' }"
    >
      <vxe-table-column
        v-for="(col, index) in tableInfo.columns"
        :key="index"
        :field="col.field"
        :title="col.title"
        :edit-render="
          col.editRender && {
            ...col.editRender,
            props: {
              ...col.editRender.props,
              disabled: tableDisable(notView, col.field),
            },
          }
        "
        :min-width="col.width ? col.width : 80"
        :fixed="col.fixed"
      >
      </vxe-table-column>
    </vxe-table>

    <!-- 规则简述 -->
    <div>
      <span>陈列说明：</span>
      <el-input :disabled='notView' v-model="displayExplain" :rows="3" type="textarea" placeholder="（1）陈列需要显眼，陈列拍照请拍光线充足情况下的正面照片
（2）如果15号后才签协议，陈列只能从下个月进行计算，当月扫码销量滚动计入下个月进行处理
（3）费用审核后发放，可向经销商核销，冲抵货款"></el-input>
    </div>

    <SelectConfig ref="productRef" @onGetSelect="productConfirm" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';
import Storage from '@/found/utils/storage';

const selectArr = {
  // month: {
  //   dictCode: 'month',
  //   value: '月',
  //   number: '',
  //   label: '按月返',
  // },
  season: {
    dictCode: 'season',
    value: '季',
    number: '',
    label: '按季返',
  },
  halfYear: {
    dictCode: 'halfYear',
    value: '半年',
    number: '',
    label: '按半年返',
  },
  year: {
    dictCode: 'year',
    value: '年',
    number: '',
    label: '按年返',
  },
};

export default {
  name: 'display',
  components: {
    SelectConfig,
  },
  props: {
    targetType: String,
    edit: String,
    // tableData: Array,
    notView: Boolean,
    productLevelList: Array,
    // rewardList: Array,
    displayTypeList: Array,
  },
  created() {
    // this.getDict();
    // 确保表格占满全屏
    this.maxTableHeight = `${
      document.querySelector('.drawer-container').clientHeight - 30 - 48 - 100 - 33 - 80 - 20
    }px`;
  },
  data() {
    return {
      rewardList: [
        {
          name: '赠品',
          key: 'gift',
          flag: '4',
          rewardComputeTypeMountVos: [],
        },
        {
          name: '现金',
          key: 'cash',
          flag: '5',
          rewardComputeTypeMountVos: [],
        },
      ],
      headerObj: {
        loginUserToken: Storage.l.get('TokenKey'),
      },
      maxTableHeight: '500px',
      bindSaleStatus: '1',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      productLevel: 'materialGroup',
      displayPolicyRanges: [],
      tableInfo: {
        columns: [
          {
            fixed: 'left',
            field: 'totalSale',
            title: '总销量(标箱)',
            width: 120,
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { input: this.targetNumInput } },
          },
          {
            field: 'januarySaleNum',
            title: '一月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'februarySaleNum',
            title: '二月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'marchSaleNum',
            title: '三月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'aprilSaleNum',
            title: '四月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'maySaleNum',
            title: '五月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'juneSaleNum',
            title: '六月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'julySaleNum',
            title: '七月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'augustSaleNum',
            title: '八月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'septemberSaleNum',
            title: '九月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'octoberSaleNum',
            title: '十月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'novemberSaleNum',
            title: '十一月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
          {
            field: 'decemberSaleNum',
            title: '十二月',
            editRender: { name: '$input', props: { type: 'number', min: 0 }, events: { change: this.monthInput } },
          },
        ],
        data: [
          {
            totalSale: 0,
            februarySaleNum: 0,
            januarySaleNum: 0,
            marchSaleNum: 0,
            aprilSaleNum: 0,
            maySaleNum: 0,
            juneSaleNum: 0,
            julySaleNum: 0,
            augustSaleNum: 0,
            septemberSaleNum: 0,
            octoberSaleNum: 0,
            novemberSaleNum: 0,
            decemberSaleNum: 0,
          },
        ],
      },
      tableLadderInfo: {
        columns: [
          {
            field: 'ladderCode',
            title: '协议阶梯编码',
          },
          {
            field: 'ladderType',
            title: '协议阶梯类型',
          },
          {
            field: 'ladderName',
            title: '协议阶梯名称',
          },
          {
            field: 'displayNumber',
            title: '陈列面个数',
          },
        ],
        data: [
          {
            ladderCode: '',
            ladderType: '',
            ladderName: '',
            displayNumber: 0,
          },
        ],
      },
      uploadRules: [
        {
          startDay: '',
          orderNum: 1,
          endDay: '',
        },
      ],
      displayType: '',
      tableData: [],
      displayExplain: '',
      recieveData: [],
      displaySampleGraphs: [],
      ladderArr: [],
      arrTag: [],
    };
  },
  watch: {
    async recieveData(v) {
      this.tableInfo.data = [
        {
          ...v.saleTarget,
        },
      ];
      this.bindSaleStatus = v.bindSaleStatus;
      this.displayExplain = v.displayExplain;
      this.displayType = v.displayType;
      if (v.displayPolicyConfigurations.length) this.productLevel = v.dimensionName;
      this.tableData = v.displayPolicyConfigurations;
      this.tableData[0].awardType = this.tableData[0].onlyHighestLevel;
      let tempRes = await this.spiltArrayBykey(v.displayPolicyConfigurations[0].displayPolicyExpressions, 'specialCode');
      tempRes = tempRes.map((item) => this.assembleObj(item, ['calculateCycle', 'rewardData'], ['calculateCycle', 'rewardData'], 'textArr'));
      this.tableData[0].displayPolicyExpressions = tempRes;
      this.getLadderData(this.tableData[0].agreementLadderCode);
      this.uploadRules = v.uploadRules;
      v.displaySampleGraphs.forEach((item) => {
        const d = item;
        d.url = item.pictureUrl;
        return d;
      });
      this.displaySampleGraphs = v.displaySampleGraphs;
    },
  },
  methods: {
    // 组装详情数据
    async spiltArrayBykey(arr, key) {
      const target = [];
      const keyMap = {};
      const dataArr = arr.map(async (val) => {
        const item = val;
        if (item.giftCode !== '') {
          item.rewardName = await this.getRaward(item.giftCode);
        }
        return item;
      });
      const res = await Promise.all(dataArr);
      res.forEach((item) => {
        const value = item[key];
        if (typeof keyMap[value] !== 'undefined') {
          target[keyMap[value]].push(item);
        } else {
          target.push([item]);
          keyMap[value] = target.length - 1;
        }
      });
      return target;
    },
    combine2Obj(obj, keys) {
      const target = {};
      for (const key of keys) {
        target[key] = obj[key];
      }
      return {
        ...selectArr[target.calculateCycle],
        number: target.rewardData,
      };
    },
    assembleObj(arr, keys, assembleKeys, assembleResultKey) {
      if (!(arr instanceof Array) || arr.length === 0) {
        return false;
      }
      const target = {
        ...arr[0],
      };
      if (assembleKeys && assembleResultKey) {
        target[assembleResultKey] = [];
      }
      arr.forEach((item) => {
        for (const key of keys) {
          if (typeof target[key] === 'object') {
            target[key].push(item[key]);
          } else {
            target[key] = [item[key]];
          }
        }
        if (assembleKeys && assembleResultKey) {
          const temp = this.combine2Obj(item, assembleKeys);
          target[assembleResultKey].push(temp);
          if (target.tagArr) {
            target.tagArr.push(temp.label);
          } else {
            target.tagArr = [temp.label];
          }
        }
      });
      return target;
    },
    // 获取阶梯数据
    getLadderData(code) {
      request.get('/cps/v1/agreement/agreementLadder/findByLadderCode', { ladderCode: code }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.ladderArr.push(res.result);
        this.ladderArr.map((value) => {
          const v = value;
          if (v.ladderType === 'display') {
            v.ladderType = '陈列';
          }
          return v;
        });
      });
    },
    //
    async getRaward(code) {
      const params = { productLevelType: 'inKind', materialCode: code };
      const res = await request.post('/mdm/mdmMaterialController/pageList', params);
      if (!res.success) return this.$message.error(res.message);
      return res.result.data[0].materialName;
    },

    // 获取数据字典
    getDict() {
      // 产品维度
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'cps-product-level',
      }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.productLevelList = res.result.filter((item) => item.dictCode === 'materialGroup');
      });
    },

    handleRemove(file, fileList) {
      console.log('removefile', file);
      this.displaySampleGraphs.splice(this.displaySampleGraphs.indexOf(file.fileName));
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log('download', file);
    },
    handleSuccess(res) {
      console.log('图片', res);
      if (res.code === 200 && res.success) {
        res.result.forEach((v) => {
          this.displaySampleGraphs.push({
            fileName: v.fileName,
            pictureUrl: v.url,
            url: v.url,
            urlPath: v.urlPath,
            urlPathPrefix: v.urlPathPrefix,
            objectName: v.objectName,
          });
        });
      }
    },

    formatterNum(val, row) {
      if (row.rewardMethodFlag === 'integral') {
        this.$set(row, 'rewardData', Number(val).toFixed(0));
      } else {
        this.$set(row, 'rewardData', Number(val).toFixed(2));
      }
    },

    closeTag(val, index) {
      this.tableData[index].displayPolicyRanges = this.tableData[index].displayPolicyRanges.filter((item) => item.specialCode !== val.specialCode);
      this.displayPolicyRanges = this.displayPolicyRanges.filter((item) => item.specialCode !== val.specialCode);
    },

    addProduct(index) {
      let functionCode = 'treety_modules_productList';
      let idKey = 'productAndLevelCode';
      if (this.productLevel === 'material') {
        functionCode = 'treety_modules_productList_material';
        idKey = 'materialCode';
      } else if (this.productLevel === 'all') {
        functionCode = 'treety_modules_productList';
        idKey = 'productAndLevelCode';
      } else if (this.productLevel === 'materialGroup') {
        functionCode = 'treety_modules_productList_materialGroup';
        idKey = 'materialGroupCode';
      } else {
        functionCode = 'treety_modules_productList_levelType';
        idKey = 'productLevelCode';
      }

      let productLevelType = null;
      switch (this.productLevel) {
        case 'brand':
          productLevelType = 'brand';
          break;
        case 'series':
          productLevelType = 'series';
          break;
        case 'max':
          productLevelType = 'max';
          break;
        case 'min':
          productLevelType = 'min';
          break;
        default:
          productLevelType = null;
          break;
      }
      const params = {
        functionCode,
        data: this.tableData[index].displayPolicyRanges,
        selectionList: [],
        idKey,
        noReset: true,
        paramData: {
          productLevelType,
        },
      };
      this.modelIndex = index;
      this.clickType = 'cp';
      this.$refs.productRef.openSelectModal(params);
    },
    clearSelectProduct(val) {
      this.displayPolicyRanges = [];
      this.tableData.forEach((item) => {
        const data = item;
        if (val === 'all') {
          data.dimensionFlag = 'DIMENSION_ALL';
          data.dimensionName = '全部产品';
        } else {
          let dimensionName = '';
          this.productLevelList.forEach((p) => {
            if (p.dictCode === this.productLevel) dimensionName = p.dictValue;
          });
          data.dimensionFlag = val;
          data.dimensionName = dimensionName;
        }
        data.displayPolicyRanges = [];
        return data;
      });
    },
    productConfirm(params) {
      console.log('商品弹框确认带出的数据', params);
      if (this.clickType === 'jt') {
        this.ladderArr = params;
        params.map((i) => {
          const item = i;
          if (item.ladderType === 'display') {
            item.ladderType = '陈列';
          }
          return item;
        });
        this.tableLadderInfo.data = params;
      }
      if (this.clickType === 'sj') {
        this.tableData[this.modelIndex].displayPolicyExpressions[this.level].rewardName = params[0].materialName;
        this.tableData[this.modelIndex].displayPolicyExpressions[this.level].giftCode = params[0].materialCode;
      }
      if (this.clickType === 'cp') {
        const displayPolicyRanges = [];
        this.displayPolicyRanges = [];
        params.forEach((item) => {
          if (this.productLevel === 'all') {
            displayPolicyRanges.push({
              ...item,
              specialName: item.productAndLevelName,
              specialCode: item.productAndLevelCode,
            });
          } else if (this.productLevel === 'material') {
            displayPolicyRanges.push({
              ...item,
              specialName: item.materialName,
              specialCode: item.materialCode,
            });
          } else if (this.productLevel === 'materialGroup') {
            displayPolicyRanges.push({
              ...item,
              specialName: item.materialGroupName,
              specialCode: item.materialGroupCode,
            });
          } else {
            displayPolicyRanges.push({
              ...item,
              specialName: item.productLevelName,
              specialCode: item.productLevelCode,
            });
          }
        });
        this.tableData[this.modelIndex].displayPolicyRanges = displayPolicyRanges;
        this.displayPolicyRanges = displayPolicyRanges;
      }
    },

    tableDisable(edit, field) {
      if (edit) {
        return true;
      }
      if (field === 'productName' || field === 'productLevelName') {
        return true;
      }
      return false;
    },
    // 总销量输入事件
    targetNumInput(val) {
      // 输入总销量 平分至12个月 不能整除则累加至第12月
      const monthTarget = Math.floor(Number(val.row.totalSale) / 12);
      this.$set(val.row, 'januarySaleNum', monthTarget);
      this.$set(val.row, 'februarySaleNum', monthTarget);
      this.$set(val.row, 'marchSaleNum', monthTarget);
      this.$set(val.row, 'aprilSaleNum', monthTarget);
      this.$set(val.row, 'maySaleNum', monthTarget);
      this.$set(val.row, 'juneSaleNum', monthTarget);
      this.$set(val.row, 'julySaleNum', monthTarget);
      this.$set(val.row, 'augustSaleNum', monthTarget);
      this.$set(val.row, 'septemberSaleNum', monthTarget);
      this.$set(val.row, 'octoberSaleNum', monthTarget);
      this.$set(val.row, 'novemberSaleNum', monthTarget);
      this.$set(val.row, 'decemberSaleNum', monthTarget + (Number(val.row.totalSale) - monthTarget * 12));
    },

    // 单月销量输入事件
    monthInput(val) {
      // 单月输入 累加至总销量
      let total = 0;
      for (const i in val.row) {
        if (Object.prototype.hasOwnProperty.call(val.row, i) && i !== '_XID' && i !== 'totalSale') {
          total += Number(val.row[i]);
        }
      }
      this.$set(val.row, 'totalSale', total);
    },

    addFre() {
      this.uploadRules.push({
        startDay: '',
        endDay: '',
        orderNum: this.uploadRules.length + 1,
      });
    },
    delFre(index) {
      this.uploadRules.splice(index, 1);
    },

    addRow() {
      if (this.tableData.length > 0) return;
      let dimensionName = '';
      this.productLevelList.forEach((item) => {
        if (item.dictCode === this.productLevel) dimensionName = item.dictValue;
      });

      this.tableData.push({
        dimensionFlag: this.productLevel === 'all' ? 'DIMENSION_ALL' : this.productLevel,
        dimensionName: this.productLevel === 'all' ? '全部产品' : dimensionName,
        policyKey: 'displayPolicy',
        displayPolicyRanges: [],
        awardType: 'Y',
        data: [],
        displayPolicyExpressions: [
          {
            displayNumber: this.tableLadderInfo.data[0].displayNumber, // 陈列面达标个数
            rewardMethodFlag: '', // 奖励方式
            rewardMethodName: '',
            rewardData: '', // 奖励数据
            awardConditionFlag: 'fixedAmount',
            awardConditionName: '固定额度',
            calculateCycle: '', // 包量达成计算周期(月,季度，半年，年)
            rewardName: '', // 奖励名称
            giftCode: '',
            textArr: [],
            tagArr: [],
            selectArr: [
              // {
              //   dictCode: 'month',
              //   value: '月',
              //   number: '',
              //   label: '按月返',
              // },
              {
                dictCode: 'season',
                value: '季',
                number: '',
                label: '按季返',
              },
              {
                dictCode: 'halfYear',
                value: '半年',
                number: '',
                label: '按半年返',
              },
              {
                dictCode: 'year',
                value: '年',
                number: '',
                label: '按年返',
              },
            ],
          },
        ],
      });
    },
    delRow(index) {
      this.tableData.splice(index, 1);
    },

    rewardChange(val, rIndex, index) {
      let rewardMethodName = '';
      this.rewardList.forEach((item) => {
        if (item.key === val) rewardMethodName = item.name;
      });
      this.tableData[index].displayPolicyExpressions[rIndex].rewardName = '';
      this.tableData[index].displayPolicyExpressions[rIndex].rewardMethodName = rewardMethodName;
    },

    addStep(rIndex, cIndex) {
      this.tableData[rIndex].displayPolicyExpressions.push({
        displayNumber: this.tableLadderInfo.data[0].displayNumber, // 陈列面达标个数
        rewardMethodFlag: '', // 奖励方式
        rewardMethodName: '',
        rewardData: '', // 奖励数据
        awardConditionFlag: 'fixedAmount',
        awardConditionName: '固定额度',
        calculateCycle: '', // 包量达成计算周期(月,季度，半年，年)
        rewardName: '', // 奖励名称
        giftCode: '',
        textArr: [],
        tagArr: [],
        selectArr: [
          // {
          //   dictCode: 'month',
          //   value: '月',
          //   number: '',
          //   label: '按月返',
          // },
          {
            dictCode: 'season',
            value: '季',
            number: '',
            label: '按季返',
          },
          {
            dictCode: 'halfYear',
            value: '半年',
            number: '',
            label: '按半年返',
          },
          {
            dictCode: 'year',
            value: '年',
            number: '',
            label: '按年返',
          },
        ],
      });
    },
    delStep(rIndex, cIndex) {
      this.tableData[rIndex].displayPolicyExpressions.splice(cIndex, 1);
    },

    // 添加比例
    addbili(index) {
      console.log(this.tableData[index]);
      this.tableData[index].displayPolicyExpressions.push({
        displayNumber: this.tableLadderInfo.data[0].displayNumber, // 陈列
        rewardMethodFlag: '', // 奖励方式
        rewardMethodName: '',
        rewardData: '', // 奖励数据
        awardConditionFlag: 'fixedAmount',
        awardConditionName: '固定额度',
        calculateCycle: '', // 包量达成计算周期(月,季度，半年，年)
        rewardName: '', // 奖励名称
        giftCode: '', // 赠品编码
        textArr: [],
        tagArr: [],
        selectArr: [
          // {
          //   dictCode: 'month',
          //   value: '月',
          //   number: '',
          //   label: '按月返',
          // },
          {
            dictCode: 'season',
            value: '季',
            number: '',
            label: '按季返',
          },
          {
            dictCode: 'halfYear',
            value: '半年',
            number: '',
            label: '按半年返',
          },
          {
            dictCode: 'year',
            value: '年',
            number: '',
            label: '按年返',
          },
        ],
      });
    },
    // 添加实物奖励
    addInKind(index, rIndex) {
      const params = {
        functionCode: 'treety_modules_addInKind',
        data: this.tableData[index].displayPolicyExpressions[rIndex].rewardName,
        selectionList: [],
        idKey: 'id',
        noReset: true,
        paramData: {
          productLevelType: 'inKind',
        },
      };
      this.modelIndex = index;
      this.level = rIndex;
      this.clickType = 'sj';
      this.$refs.productRef.openSelectModal(params);
    },
    // 删除行
    deleteRow(index, rIndex) {
      if (this.tableData[index].displayPolicyExpressions.length > 1) {
        this.tableData[index].displayPolicyExpressions.splice(rIndex, 1);
      }
    },
    // 删除周期
    deleteTag(index, rIndex, tIndex) {
      console.log(index, rIndex, tIndex);
      if (this.tableData[index].displayPolicyExpressions[rIndex].textArr.length >= 1) {
        // this.tableData[index].quantifyExpressions[rIndex].textArr.splice(tIndex, 1);
        this.tableData[index].displayPolicyExpressions[rIndex].textArr[tIndex].isdisable = true;
      }
    },
    // 最高达成奖励
    highest(index) {
      this.tableData[index].awardType = 'Y';
    },
    // 每次达成奖励
    everyTime(index) {
      this.tableData[index].awardType = 'N';
    },
    // 添加阶梯
    addConfig(index) {
      const params = {
        functionCode: 'treety_modules_addDisplay',
        data: this.ladderArr,
        selectionList: [],
        idKey: 'productLevelCode',
        noReset: true,
        requestType: 'GET',
        paramData: {
          productLevelType: 'ladder',
          enableStatus: '009',
          ladderType: 'display',
        },
      };
      this.modelIndex = index;
      this.clickType = 'jt';
      this.$refs.productRef.openSelectModal(params);
    },
    // 删除阶梯
    delLadeer(index, lIndex) {
      this.tableData[index].data.splice(lIndex, 1);
    },
    // 多选下拉值发生改变触发事件
    changTag(val, index, rIndex) {
      console.log('changeTag', val);
      let data = [];
      data = val;
      this.tableData[index].displayPolicyExpressions[rIndex].textArr = [];
      // this.arrTag = val;

      data.forEach((v, i) => {
        if (v === '月') {
          this.tableData[index].displayPolicyExpressions[rIndex].textArr.push({
            dictCode: 'month',
            value: '月',
            number: '',
            label: '按月返',
          });
        } else if (v === '季') {
          this.tableData[index].displayPolicyExpressions[rIndex].textArr.push({
            dictCode: 'season',
            value: '季',
            number: '',
            label: '按季返',
          });
        } else if (v === '半年') {
          this.tableData[index].displayPolicyExpressions[rIndex].textArr.push({
            dictCode: 'halfYear',
            value: '半年',
            number: '',
            label: '按半年返',
          });
        } else if (v === '年') {
          this.tableData[index].displayPolicyExpressions[rIndex].textArr.push({
            dictCode: 'year',
            value: '年',
            number: '',
            label: '按年返',
          });
        }
      });
    },
    // 多选下拉 移除tag事件
    removeTag(val, index, rIndex) {
      console.log('removetableData', this.tableData[index].displayPolicyExpressions[rIndex].textArr);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  border: 1px solid #DADADA;
  border-radius: 5px;
  padding: 20px;
}
.flex {
  display: flex;
  justify-content: space-between;
}

.centerDisplay{
  display:flex;
  justify-content:center;
  align-items: center;
}

.titleflex{
  display: flex;
  justify-content: space-between;
}
table,
tr,
td{
  border: 1px solid #E8EAEC;
  text-align: center;
}
td{
  padding: 0 2px;
}
table{
  width: 100%;
  border-collapse:collapse;
}
.iconpostion {
  position: relative;
  top: -5px;
  right: 10px;
  font-weight: 700;
}

.ladderTableClass{
  margin: 10px 0;
}

.title {
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
}
.weekly {
  display: flex;
  margin: 10px 0;
}
.rowLineIcon {
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
}
.closeIcon {
  color: #F56C6C !important;
}

.rowSection {
  position: relative;
  border: 1px solid #d3d5d8;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0;
}

.close {
  display: inline-block;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45) !important;
  position: absolute;
  right: 2px;
  top: 2px;

  &:hover {
    color: rgba(0, 0, 0, 0.8) !important;
  }
}

.tableItem {
  width: 200px;
  margin-bottom: 10px;
}

.el-input {
  display: flex;
  height: 32px !important;
  .el-input-group__append {
    height: 32px !important;
  }
  .apend {
    display: inline-block;
    // height: 30px;
    line-height: 32px;
  }
}
/deep/.el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 90px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 80px !important;
  height: 80px !important;
}
/deep/.el-upload-list--picture-card .el-upload-list__item-actions {
  i {
    font-size: 16px;
  }
}
</style>
