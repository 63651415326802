<!--
 * @Autor: yzr
 * @Date: 2021-07-19 15:58:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-18 19:24:05
 * @Description: 协议模板配置-新增表单
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import AddForm from '../components/AddForm.vue';

formCreate.component('addForm', AddForm);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'treety_modules_add',
      orgList: [],
      channelList: [],
      policyList: [],
      module: [],
    };
  },
  created() {
    this.getDict();
  },
  async mounted() {
    // if (this.formConfig.id) this.getFormDetail();
  },

  methods: {
    // 获取数据字典
    getDict() {
      // 渠道
      request
        .post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'channel',
        })
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          this.channelList = res.result;
        });
    },
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'templateName') {
        item.props = {
          ...item.props,
          maxlength: 50,
        };
      }
      if (item.field === 'autoSign') {
        item.value = 'N';
      }
      // if (item.field === 'templateChannelRelationships') {
      //   item.props = {
      //     ...item.props,
      //     multiple: true,
      //   };
      // }
      if (item.field === 'templatePolicyRelationships') {
        // console.log(item);
        item.props = {
          ...item.props,
          multiple: true,
        };
        // item.type = 'show';
        item.on = {
          change: this.policyChange,
        };
      }
      if (item.field === 'signDate' || item.field === 'agreeDate') {
        item.props = {
          ...item.props,
          'unlink-panels': true,
          type: 'datetimerange',
          'default-time': ['00:00:00', '23:59:59'],
        };
      }
      if (item.field === 'templateOrgRelationships') {
        // 所属组织名称
        item.props = {
          ...item.props,
          restful: '/mdm/baseTreeController/orgTree',
          restfulParams: {
            enableStatus: '009',
          },
          headers: { functionCode: 'select-org' },
          props: {
            value: 'code', // 唯一标识
            key: 'code',
            label: 'name', // 标签显示
            children: 'children', // 子级
          },
        };
        item.on = {
          cpsChange: this.orgTreeChange,
        };
      }
      // if (item.field === 'templateTags') {
      //   item.restful = '/cps/v1/participator/tag/findTerminalTagByTagDescriptionLike';
      //   item.restType = 'GET';
      //   item.restfulParams = { tagDescription: '标签' };
      //   item.optionsKey = { label: 'tagDescription', value: 'participatorCode' };
      //   item.options = [];
      //   item.props = {
      //     ...item.props,
      //     clearable: true,
      //     loading: false,
      //     filterable: true,
      //     filterMethod: this.filterMethod,
      //     // remote: true,
      //     multiple: true,
      //     // remoteParams: 'tagDescription',
      //   };
      // }
      if (item.field === 'addForm') {
        item.props = {
          ...item.props,
          modules: [],
          policyList: this.policyList,
        };
        item.value = '123';
      }

      if (item.field === 'agreeDate') {
        item.on = {
          change: this.startDateChange,
        };
      }
      return item;
    },

    // 签署时间限定在有效期内
    startDateChange(val) {
      const signDate = this.getRule('signDate');
      signDate.props = {
        ...signDate.props,
        // pickerOptions: {
        //   // disabledDate: (time) => time.getTime() < new Date(val[0]) || time.getTime() >= new Date(val[1]),
        //   disabledDate: (time) => time.getTime() < (Date.now() - 8.64e7) || time.getTime() >= new Date(val[1]),
        // },
      };

      // 签署时间在有效期外 则清除签署时间
      if (signDate.value) {
        if (val[0] > signDate.value[0] || val[1] < signDate.value[1]) signDate.value = [];
      }
    },
    filterMethod(val) {
      request
        .get('/cps/v1/participator/tag/findTerminalTagByTagDescriptionLike', {
          tagDescription: val,
        })
        .then((res) => {
          if (!res.success) return;
          res.result.forEach((item) => {
            const data = item;
            data.label = item.tagDescription;
            data.value = item.participatorCode;
            return data;
          });
          this.getRule('templateTags').options = res.result || [];
        });
    },
    policyChange(val) {
      this.module = [...val];
      this.getRule('addForm').props.modules = this.module;
    },
    orgTreeChange(val, label, extra) {
      const orgList = [];
      val.forEach((item) => {
        orgList.push({
          orgCode: item,
        });
      });
      label.forEach((item, index) => {
        orgList[index].orgName = item;
      });
      this.orgList = orgList;
    },
    // 表单渲染完成后调用
    formComplete() {
      this.getRule('addForm').on.change = (data) => {
        this.setValue({ templateDealerRelationships: data });
      };
      this.setValue({
        autoSign: false,
      });
      // console.log(this.rule);

      const agreeDate = this.getRule('agreeDate');
      agreeDate.props = {
        ...agreeDate.props,
        // pickerOptions: {
        //   disabledDate: (time) => time.getTime() < (Date.now() - 8.64e7),
        // },
      };
      const signDate = this.getRule('signDate');
      signDate.props = {
        ...signDate.props,
        // pickerOptions: {
        //   disabledDate: (time) => time.getTime() < (Date.now() - 8.64e7),
        // },
      };

      if (this.formConfig.id) this.getFormDetail();
    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      const addFormInstance = this.fApi.el('addForm');
      console.log(formData.templateDealerRelationships, formData);
      let saleChannel = '';
      if (formData.saleChannel === 'exclusiveCode') {
        saleChannel = 'exclusive';
      } else {
        saleChannel = formData.saleChannel;
      }
      formData.templateDealerRelationships.push(saleChannel);
      const channel = [];
      const policy = [];
      if (formData.templateChannelRelationships) {
        formData.templateChannelRelationships.forEach((item) => {
          this.channelList.map((c) => {
            if (item === c.dictCode) {
              channel.push({
                channelCode: c.dictCode,
                channelName: c.dictValue,
              });
            }
            return c;
          });
        });
      }

      // 是否选择政策
      if (addFormInstance.modules) {
        // addFormInstance.modules.forEach((m) => {
        addFormInstance.policyList.forEach((p) => {
          if (p.key === addFormInstance.modules) {
            policy.push({
              policyCode: p.key,
              policyName: p.name,
            });
          }
          // });
        });
      }

      // 是否选择阶梯
      if (addFormInstance.$refs.Display.tableInfo.data.length) {
        addFormInstance.$refs.Display.tableInfo.data.forEach((m) => {
          if (m.ladderCode === '') {
            return this.$message.warning('请添加阶梯');
          }
        });
      }

      // 扫码政策
      const scanCode = addFormInstance.modules.includes('scanCode')
        ? this.restScanCodeData(addFormInstance.$refs.ScanTable.tableData)
        : {};
      // 包量政策
      const quantifyPolicy = addFormInstance.modules.includes('quantifyPolicy')
        ? this.restQuantifyPolicyData(
          addFormInstance.$refs.PackageNum.tableData,
        )
        : {};

      // 陈列政策
      const displayPolicy = addFormInstance.modules.includes('displayPolicy')
        ? {
          bindSaleStatus: addFormInstance.$refs.Display.bindSaleStatus,
          displayExplain: addFormInstance.$refs.Display.displayExplain,
          displayType: addFormInstance.$refs.Display.displayType,
          saleTarget: addFormInstance.$refs.Display.tableInfo.data[0],
          uploadRules: addFormInstance.$refs.Display.uploadRules,
          displaySampleGraphs:
              addFormInstance.$refs.Display.displaySampleGraphs,
          displayPolicyConfigurations: this.restDisplayLadderData(
            addFormInstance.$refs.Display.tableData,
            addFormInstance.$refs.Display.tableLadderInfo.data[0].ladderCode,
          ),
          dimensionFlag: this.getKey(
            this.restDisplayLadderData(
              addFormInstance.$refs.Display.tableData,
            ),
            'flag',
          ),
          dimensionName: this.getKey(
            this.restDisplayLadderData(
              addFormInstance.$refs.Display.tableData,
            ),
            'name',
          ),
        }
        : {};

      const params = {
        // 协议模板信息
        profitAgreementTemplate: {
          templateName: formData.templateName,
          autoSign: formData.autoSign ? 'Y' : 'N',
          saleChannel: formData.saleChannel || '',
          templateTags: [],
          signStartTime: `${formData.signDate[0]}`,
          signEndTime: `${formData.signDate[1]}`,
          effectiveStartTime: `${formData.agreeDate[0]}`,
          effectiveEndTime: `${formData.agreeDate[1]}`,
          agreementText:
            addFormInstance.$refs.TreetyContent.currentEditor.txt.html(),
          remark: addFormInstance.$refs.ScanTable.remark,
          templateOrgRelationships: this.orgList,
          // templateChannelRelationships: channel,
          templatePolicyRelationships: policy,
          templateDealerRelationships: addFormInstance.modules.includes(
            'scanCode',
          )
            ? formData.templateDealerRelationships.map((i) => ({
              dealerTypeCode: i,
            }))
            : null,
        },
        // 政策信息
        scanCode: addFormInstance.modules.includes('scanCode')
          ? scanCode
          : null,
        quantifyPolicy: addFormInstance.modules.includes('quantifyPolicy')
          ? quantifyPolicy
          : null,
        displayPolicy: addFormInstance.modules.includes('displayPolicy')
          ? displayPolicy
          : null,
      };
      console.log(params);
      /**
       * 政策相关校验
       * 至少选择一种政策
       * 政策必须包含一条政策标准
       * 政策标准信息必填
       * 扫码政策选择相同条码类型时 奖励方式不能相同
       */
      //  console.log()
      if (!params.scanCode && !params.quantifyPolicy && !params.displayPolicy) return this.$message.warning('至少选择一种分利政策');
      if (params.scanCode) {
        const notEmpty = params.scanCode.every(
          (scan) => scan.scanCodeParticipators.length,
        );
        if (!notEmpty) return this.$message.error('填写扫码政策分利信息');
        let existReward = false;
        let childReward = false;
        params.scanCode.forEach((scan) => {
          scan.scanCodeParticipators.forEach((par) => {
            par.scanCodeExpressions.forEach((exp1, index1) => {
              par.scanCodeExpressions.forEach((exp2, index2) => {
                if (index1 !== index2) {
                  if (
                    exp1.barCodeTypeFlag === exp2.barCodeTypeFlag
                    && exp1.rewardMethodFlag === exp2.rewardMethodFlag
                  ) existReward = true;
                  else existReward = false;
                }
              });

              // 返利对象验重
              const arr = exp1.scanCodeExpressions.map(
                (item) => item.rewardMethodFlag,
              );
              childReward = arr.some((item, idx) => arr.indexOf(item) !== idx);
            });
          });
        });
        if (existReward) return this.$message.warning('扫码政策存在相同奖励方式');
        if (childReward) return this.$message.warning('扫码政策返利存在相同奖励方式');

        // 未填写内容校验
        // console.log(params.scanCode);
        let noRewardData = false;
        let noRewardRate = false;
        let noDealerRewardData = false;
        let noDealerRewardRate = false;
        let noTerminalRewardData = false;
        let noTerminalRewardRate = false;
        const maxPercent = [];
        const maxDealerPercent = [];
        const maxTerminalPercent = false;
        params.scanCode.forEach((scan) => {
          scan.scanCodeParticipators.forEach((sp) => {
            sp.scanCodeExpressions.forEach((se) => {
              if (se.awardConditionFlag === 'fixedAmount') {
                if (!se.rewardData) noRewardData = true;
                else noRewardData = false;
              } else {
                let totalPercent = 0;
                const data = se;
                se.ladder.rateList.forEach((r) => {
                  totalPercent += Number(r.percent);
                  if (!r.cash || !r.percent) noRewardRate = true;
                  else noRewardRate = false;
                });
                data.totalPercent = totalPercent;
              }

              // 返利
              se.scanCodeExpressions.forEach((sce) => {
                // 经销商
                if (sce.participatorFlag === '1') {
                  if (sce.awardConditionFlag === 'fixedAmount') {
                    if (!sce.rewardData) noDealerRewardData = true;
                    else noDealerRewardData = false;
                  } else {
                    let totalPercent = 0;
                    const data = sce;
                    JSON.parse(sce.rewardData).forEach((r) => {
                      totalPercent += Number(r.percent);
                      if (!r.cash || !r.percent) noDealerRewardRate = true;
                      else noDealerRewardRate = false;
                    });
                    data.totalPercent = totalPercent;
                  }
                }

                // 终端
                if (sce.participatorFlag === '2') {
                  if (sce.awardConditionFlag === 'fixedAmount') {
                    if (!sce.rewardData) noTerminalRewardData = true;
                    else noTerminalRewardData = false;
                  } else {
                    let totalPercent = 0;
                    const data = sce;
                    JSON.parse(sce.rewardData).forEach((r) => {
                      totalPercent += Number(r.percent);
                      if (!r.cash || !r.percent) noTerminalRewardRate = true;
                      else noTerminalRewardRate = false;
                    });
                    data.totalPercent = totalPercent;
                  }
                }
              });

              const maxDealer = se.scanCodeExpressions.find(
                (s) => s.totalPercent && s.totalPercent !== 100,
              );
              if (typeof maxDealer !== 'undefined') {
                maxDealerPercent.push(maxDealer);
              }
            });

            const max = sp.scanCodeExpressions.find(
              (s) => s.totalPercent && s.totalPercent !== 100,
            );
            if (typeof max !== 'undefined') {
              maxPercent.push(max);
            }
          });
        });

        // console.log(maxDealerPercent, maxPercent);

        if (maxPercent.length || maxDealerPercent.length) return this.$message.warning('奖励几率总和应等于100%');
        if (noRewardData) return this.$message.warning('请填写扫码政策奖励额度');
        if (noRewardRate) return this.$message.warning('请填写扫码政策定额随机数额');
        if (noDealerRewardData) return this.$message.warning('请填写经销商奖励额度');
        if (noDealerRewardRate) return this.$message.warning('请填写经销商定额随机数额');
        if (noTerminalRewardData) return this.$message.warning('请填写终端奖励额度');
        if (noTerminalRewardRate) return this.$message.warning('请填写终端定额随机数额');
      }

      if (params.quantifyConfigurations) {
        let emptyQuantify = false;
        if (!params.quantifyPolicy.quantifyConfigurations.length) return this.$message.error('填写包量政策分利信息');
        params.quantifyPolicy.quantifyConfigurations.forEach((quantify) => {
          quantify.quantifyExpressions.forEach((i) => {
            if (!i.reachRate || !i.rewardData || !i.rewardMethodFlag) emptyQuantify = true;
            else emptyQuantify = false;
          });
        });
        if (emptyQuantify) return this.$message.warning('请填写包量分利政策');

        // todo
      }

      if (params.displayPolicy) {
        let emptyDispaly = false;
        if (!params.displayPolicy.displayPolicyConfigurations.length) return this.$message.error('填写陈列政策分利信息');
        params.displayPolicy.displayPolicyConfigurations.forEach((display) => {
          display.displayPolicyExpressions.forEach((i) => {
            if (!i.reachRate || !i.rewardData || !i.rewardMethodFlag) emptyDispaly = true;
            else emptyDispaly = false;
          });
        });
        if (emptyDispaly) return this.$message.warning('请填写陈列分利政策');
      }
      console.log('params', params);
      // 修改请求地址
      request
        .post('/cps/v1/agreement/template/createDetails', params)
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        });
    },

    // 重组扫码政策数据
    restScanCodeData(data) {
      if (!data.length) return;
      data.forEach((item) => {
        item.scanCodeParticipators.forEach((child) => {
          child.scanCodeExpressions.forEach((g) => {
            const gData = g;
            const scanCodeExpressions = [];
            gData.barCodeTypeFlag = gData.ladder.controls[0].value
              ? gData.ladder.controls[0].value
              : this.$message.warning('请填写扫码政策条码类型');
            gData.barCodeTypeName = gData.ladder.controls[0].selectOptions.find(
              (f) => f.key === gData.ladder.controls[0].value,
            ).value;
            gData.rewardMethodFlag = gData.ladder.controls[1].value
              ? gData.ladder.controls[1].value
              : this.$message.warning('请选择扫码政策奖励类型');
            gData.rewardMethodName = gData.ladder.controls[1].selectOptions.find(
              (f) => f.key === gData.ladder.controls[1].value,
            ).value;
            gData.awardConditionFlag = gData.ladder.controls[2].value
              ? gData.ladder.controls[2].value
              : this.$message.warning('请选择扫码政策奖励规则');
            gData.awardConditionName = gData.ladder.controls[2].selectOptions.find(
              (f) => f.key === gData.ladder.controls[2].value,
            ).value;
            gData.rewardData = gData.ladder.controls[3].hide
              ? JSON.stringify(gData.ladder.rateList)
              : gData.ladder.controls[3].value;
            gData.participatorFlag = child.participatorFlag;
            gData.participatorName = child.participatorName;

            // 奖励经销商
            gData.dealerList.forEach((dealer) => {
              scanCodeExpressions.push({
                // barCodeTypeFlag: dealer.controls[0].value,
                rewardMethodFlag: dealer.controls[0].value
                  ? dealer.controls[0].value
                  : this.$message.warning('请选择经销商奖励类型'),
                rewardMethodName: dealer.controls[0].selectOptions.find(
                  (f) => f.key === dealer.controls[0].value,
                ).value,
                awardConditionFlag: dealer.controls[1].value
                  ? dealer.controls[1].value
                  : this.$message.warning('请选择经销商奖励规则'),
                awardConditionName: dealer.controls[1].selectOptions.find(
                  (f) => f.key === dealer.controls[1].value,
                ).value,
                rewardData: dealer.controls[2].hide
                  ? JSON.stringify(dealer.rateList)
                  : dealer.controls[2].value,
                participatorFlag: '1',
                participatorName: '经销商出库',
              });
            });
            // 奖励终端
            gData.terminalList.forEach((terminal) => {
              scanCodeExpressions.push({
                rewardMethodFlag: terminal.controls[0].value
                  ? terminal.controls[0].value
                  : this.$message.warning('请选择终端奖励类型'),
                rewardMethodName: terminal.controls[0].selectOptions.find(
                  (f) => f.key === terminal.controls[0].value,
                ).value,
                awardConditionFlag: terminal.controls[1].value
                  ? terminal.controls[1].value
                  : this.$message.warning('请选择终端奖励规则'),
                awardConditionName: terminal.controls[1].selectOptions.find(
                  (f) => f.key === terminal.controls[1].value,
                ).value,
                rewardData: terminal.controls[2].hide
                  ? JSON.stringify(terminal.rateList)
                  : terminal.controls[2].value,
                participatorFlag: '2',
                participatorName: '终端',
              });
            });
            gData.scanCodeExpressions = scanCodeExpressions;
            return gData;
          });
        });
      });
      return data;
    },

    // 重组包量政策数据
    restQuantifyPolicyData(data) {
      console.log('包量数据', data);
      const qData = [];
      const oldData = [];
      let configData = {};
      let ladderCode = '';
      data.forEach((val) => {
        let num = 1;
        val.quantifyExpressions.forEach((item) => {
          item.textArr.forEach((res) => {
            qData.push({
              ...item,
              calculateCycle: res.dictCode,
              rewardData: Number(res.number),
              specialCode: `第${num}次`,
            });
          });
          num += 1;
        });
        val.data.forEach((v) => {
          ladderCode = v.ladderCode;
        });
        const tempMaterial = [];
        // 处理物料组信息
        val.quantifyMaterials.forEach((qm) => {
          const tempSelect = qm.selectArray || [];
          if (tempSelect && tempSelect.length > 0) {
            // 有选择奖励周期
            tempSelect.forEach((ts) => {
              tempMaterial.push({
                ...qm,
                taskType: ts.dictCode,
                rewardData: ts.number,
              });
            });
          } else {
            tempMaterial.push(qm);
          }
        });
        console.log('转换后的数据', tempMaterial);
        oldData.push({
          ...val,
          quantifyExpressions: qData,
          onlyHighestLevel: val.awardType,
          agreementLadderCode: ladderCode,
          quantifyMaterials: tempMaterial,
        });
        configData = {
          dimensionFlag: val.dimensionFlag,
          dimensionName: val.dimensionName,
          quantifyConfigurations: oldData,
        };
      });
      console.log('包量测试的', configData);

      return configData;
    },

    // 重组陈列政策阶梯数据
    restDisplayLadderData(data, laddercode) {
      console.log('陈列', data);
      const qData = [];
      const configData = [];
      data.forEach((val) => {
        let num = 1;
        val.displayPolicyExpressions.forEach((item) => {
          item.textArr.forEach((res) => {
            qData.push({
              ...item,
              calculateCycle: res.dictCode,
              rewardData: Number(res.number),
              specialCode: `第${num}次`,
            });
          });
          num += 1;
        });
        configData.push({
          ...val,
          onlyHighestLevel: val.awardType,
          agreementLadderCode: laddercode,
          displayPolicyExpressions: qData,
        });
      });
      return configData;
    },
    getKey(data, code) {
      let value = '';
      if (code === 'flag') {
        for (const item in data[0]) {
          if (item === 'dimensionFlag') {
            value = data[0].dimensionFlag;
            return value;
          }
        }
      }

      if (code === 'name') {
        for (const item in data[0]) {
          if (item === 'dimensionName') {
            value = data[0].dimensionName;
            return value;
          }
        }
      }
    },

    // 表单详情
    getFormDetail() {
      request
        .get('/cps/v1/agreement/template/findPolicyDetailsById', {
          id: this.formConfig.id,
        })
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          const addFormInstance = this.fApi.el('addForm');
          const channel = [];
          let org = '';
          const policy = [];
          const { result } = res;
          result.templateOrgRelationships.forEach((item) => {
            org += `${item.orgCode},`;
          });
          result.templatePolicyRelationships.forEach((item) => {
            policy.push(item.policyCode);
            addFormInstance.enableTabs.push({
              name: item.policyName,
              key: item.policyCode,
            });
          });
          // result.templateChannelRelationships.forEach((item) => {
          //   channel.push(item.channelCode);
          // });
          // console.log(channel, org, policy);
          this.setValue({
            templateName: result.templateName,
            saleChannel: result.saleChannel,
            autoSign: result.autoSign === 'Y',
            signDate: [result.signStartTime, result.signEndTime],
            agreeDate: [result.effectiveStartTime, result.effectiveEndTime],
            templateOrgRelationships: org,
            templatePolicyRelationships: policy,
            // templateChannelRelationships: channel,
            addForm: result.details,
          });
          // });
          result.templateChannelRelationships.forEach((item) => {
            channel.push(item.channelCode);
          });
          console.log('详情', channel, org, policy);
          this.setValue({
            templateName: result.templateName,
            autoSign: result.autoSign === 'Y',
            signDate: [result.signStartTime, result.signEndTime],
            agreeDate: [result.effectiveStartTime, result.effectiveEndTime],
            templateOrgRelationships: org,
            templatePolicyRelationships: policy,
            templateChannelRelationships: channel,
            addForm: result.details,
          });

          addFormInstance.$refs.TreetyContent.receiveTxt = result.agreementText;
          addFormInstance.$refs.ScanTable.remark = result.remark;
          addFormInstance.$refs.ScanTable.templateDealerRelationships = result.templateDealerRelationships.map(
            (item) => item.dealerTypeCode,
          );
          addFormInstance.modules = policy;
          addFormInstance.activeName = policy[policy.length - 1];
          addFormInstance.details = result.details;
          addFormInstance.notView = true;
        });
    },
  },
};
</script>
