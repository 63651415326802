<!--
 * @Autor: yzr
 * @Date: 2021-07-21 18:05:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-15 11:04:12
 * @Description: 扫码政策表格
-->
<template>
  <div>
    <div class="fixTitle">
      <!-- <div style="display: flex;">
        <span>经销商类型：</span>
        <el-select v-model="templateDealerRelationships" placeholder="请选择经销商类型" :disabled='notView' @change="selectDealer" multiple clearable>
          <el-option v-for="item in delaerList" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode">
          </el-option>
        </el-select>
      </div> -->
      <div style="display: flex;">
        <span>产品维度：</span>
        <el-select v-model="productLevel" placeholder="请选择" :disabled='notView' @change="clearSelectProduct">
          <el-option v-for="item in productLevelList" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode">
          </el-option>
        </el-select>
      </div>
      <el-button v-if="!notView" type='primary' icon="el-icon-plus" @click="addRow">添加政策</el-button>
    </div>
    <div :style="{maxHeight: maxTableHeight, overflowY: 'auto'}">
      <div class="rowSection" v-for="(tableItem, index) in tableData" :key="index">
        <a v-if="!notView" class="el-icon-close close" @click="() => delRow(index)"></a>
        <div style="background: #f9f9f9;padding: 0 20px;">
          <div class='rowLineIcon'>
            <span>产品：</span>
            <a v-if="!notView && productLevel !== 'all'" style="line-height: 36px" class="el-icon-circle-plus-outline" @click="() => addProduct(index)">添加产品</a>
          </div>
          <el-tag v-for="tag in tableItem.scanCodeRanges" :key="tag.specialCode" :closable='!notView' style="margin-right: 5px" effect='plain' @close='() => closeTag(tag, index)'>
            {{tag.specialName}}
          </el-tag>
        </div>
        <span>扫码奖励标准：</span>
        <RewordStandard :notView="notView" :rowIndex="index"
        :list="tableItem.scanCodeParticipators" :addStandard="addStandard" :delStandard="delStandard"
        :addRowStandard="addRowStandard" :delRowStandard="delRowStandard" :addTerminalLadder='addTerminalLadder' :delTerminalLadder='delTerminalLadder' :addDealerLadder='addDealerLadder' :delDealerLadder='delDealerLadder' />
        <div v-if="tableItem.scanCodeParticipators.length < 3 && !notView" style="padding-left: 100px;">
          <el-button v-if="tableItem.scanCodeParticipators.length ? tableItem.scanCodeParticipators.every(item => item.participatorFlag !== '1') : true" type='primary' icon="el-icon-plus" @click="() => addStandard('1', index)">
            经销商出库
          </el-button>
          <el-button v-if="tableItem.scanCodeParticipators.length ? tableItem.scanCodeParticipators.every(item => item.participatorFlag !== '2') : true" type='primary' icon="el-icon-plus" @click="() => addStandard('2', index)">
            终端
          </el-button>
          <el-button v-if="tableItem.scanCodeParticipators.length ? tableItem.scanCodeParticipators.every(item => item.participatorFlag !== '3') : true" type='primary' icon="el-icon-plus" @click="() => addStandard('3', index)">
            消费者
          </el-button>
        </div>
      </div>
    </div>
    <FormHeader class="form-header" :visible='true' title="扫码分利规则概述" />
    <el-input :disabled='notView' v-model="remark" type="textarea" placeholder="请简要说明分利规则"></el-input>

    <SelectConfig ref="productRef" @onGetSelect="productConfirm" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import FormHeader from '@/found/components/form/components/form_header.vue';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';
import RewordStandard from './RewordStandard.vue';

export default {
  name: 'ScanTable',
  created() {
    // 确保表格占满全屏
    this.maxTableHeight = `${document.querySelector('.drawer-container')
      .clientHeight
      - 30
      - 48
      - 105}px`;
    // 经销商类型
    request
      .post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'dealer_type',
      })
      .then((res) => {
        if (!res.success) return this.$message.error(res.message);
        res.result.forEach((item) => {
          const data = item;
          data.key = item.dictCode;
          data.value = item.dictValue;
          return data;
        });
        this.delaerList = res.result;
      });
    // this.getDict();
  },
  components: {
    RewordStandard,
    FormHeader,
    SelectConfig,
  },
  data() {
    return {
      allAlign: null,
      maxTableHeight: '500px',
      name: '',
      modelIndex: 0,
      productLevel: 'materialGroup',
      tableData: [],
      remark: '',
      typeOptions: [
        {
          value: '1',
          label: '经销商出库',
        },
        {
          value: '2',
          label: '终端',
        },
        {
          value: '3',
          label: '消费者',
        },
      ],
      scanCodeRanges: [],
      recieveData: [],
      delaerList: [],
      templateDealerRelationships: [],
    };
  },

  props: {
    notView: {
      type: Boolean,
      default: () => false,
    },
    barCodeList: Array,
    productLevelList: Array,
    rewardList: Array,
  },

  watch: {
    recieveData(v) {
      console.log(v);
      // 条码类型
      request
        .post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'BarCodeType',
        })
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          res.result.forEach((item) => {
            const data = item;
            data.key = item.dictCode;
            data.value = item.dictValue;
            return data;
          });
          const barCodeList = res.result;

          // 奖励类型
          request.get('/cps/v1/reward/mount/findReward').then((req) => {
            if (!req.success) return this.$message.error(req.message);
            req.result.forEach((item) => {
              const data = item;
              data.value = item.name;
              return data;
            });
            const rewardList = req.result;

            if (v.length) this.productLevel = v[0].dimensionName;
            v.forEach((nv) => {
              nv.scanCodeParticipators.forEach((sc) => {
                sc.scanCodeExpressions.forEach((se) => {
                  const row = se;
                  let rewardTypeList = [];
                  rewardList.forEach((item) => {
                    if (item.key === row.rewardMethodFlag) {
                      item.rewardComputeTypeMountVos.forEach((r) => {
                        const data = r;
                        data.value = r.name;
                        return data;
                      });
                      rewardTypeList = item.rewardComputeTypeMountVos;
                    }
                  });
                  const rate = JSON.parse(row.rewardData);
                  const isArray = rate instanceof Array;
                  row.ladder = {
                    text: '',
                    rateList: rate,
                    hide: isArray,
                    controls: [
                      {
                        label: '每',
                        controlType: 'select',
                        selectOptions: barCodeList,
                        value: row.barCodeTypeFlag,
                      },
                      {
                        label: '奖励',
                        controlType: 'select',
                        selectType: 'reward',
                        selectOptions: rewardList,
                        value: row.rewardMethodFlag,
                      },
                      {
                        controlType: 'select',
                        selectOptions: rewardTypeList,
                        value: row.awardConditionFlag,
                      },
                      {
                        controlType: 'input',
                        hide: isArray,
                        value: row.rewardData,
                        type: 'number',
                      },
                      {
                        controlType: 'labelPostfix',
                        value:
                          row.rewardMethodFlag === 'integral' ? '积分' : '元',
                      },
                    ],
                  };
                  row.dealerList = [];
                  row.terminalList = [];

                  if (row.scanCodeExpressions) {
                    row.scanCodeExpressions.forEach((rs) => {
                      const rsRate = JSON.parse(rs.rewardData);
                      const isRsArray = rsRate instanceof Array;
                      // 终端
                      if (rs.participatorFlag === '2') {
                        row.terminalList.push({
                          text: '',
                          hide: isRsArray,
                          rateList: rsRate,
                          controls: [
                            {
                              label: '奖 励 终 端',
                              controlType: 'select',
                              selectOptions: rewardList,
                              selectType: 'reward',
                              value: rs.rewardMethodFlag,
                            },
                            {
                              controlType: 'select',
                              selectOptions: rewardTypeList,
                              value: rs.awardConditionFlag,
                            },
                            {
                              controlType: 'input',
                              hide: isRsArray,
                              value: rs.rewardData,
                              type: 'number',
                            },
                            {
                              controlType: 'labelPostfix',
                              value:
                                rs.rewardMethodFlag === 'integral'
                                  ? '积分'
                                  : '元',
                            },
                          ],
                        });
                      }

                      // 经销商
                      if (rs.participatorFlag === '1') {
                        row.dealerList.push({
                          text: '',
                          hide: isRsArray,
                          rateList: rsRate,
                          controls: [
                            {
                              label: '奖励经销商',
                              controlType: 'select',
                              selectOptions: rewardList,
                              selectType: 'reward',
                              value: rs.rewardMethodFlag,
                            },
                            {
                              controlType: 'select',
                              selectOptions: rewardTypeList,
                              value: rs.awardConditionFlag,
                            },
                            {
                              controlType: 'input',
                              hide: isRsArray,
                              value: rs.rewardData,
                              type: 'number',
                            },
                            {
                              controlType: 'labelPostfix',
                              value:
                                rs.rewardMethodFlag === 'integral'
                                  ? '积分'
                                  : '元',
                            },
                          ],
                        });
                      }
                    });
                  }
                  return row;
                });
              });
            });
            // console.log(v);
            this.tableData = v;
          });
        });
    },
  },

  methods: {
    // 选中经销商类型
    selectDealer() {
      // console.log(this.templateDealerRelationships);
      this.$emit('changeDealer', this.templateDealerRelationships);
    },
    // 获取数据字典
    getDict() {
      // 产品维度
      request
        .post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'cps-product-level',
        })
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          this.productLevelList = res.result.filter(
            (item) => item.dictCode === 'materialGroup',
          );
        });

      // 条码类型
      request
        .post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'BarCodeType',
        })
        .then((res) => {
          if (!res.success) return this.$message.error(res.message);
          res.result.forEach((item) => {
            const data = item;
            data.key = item.dictCode;
            data.value = item.dictValue;
            return data;
          });
          this.barCodeList = res.result;
        });

      // 奖励类型
      request.get('/cps/v1/reward/mount/findReward').then((res) => {
        if (!res.success) return this.$message.error(res.message);
        res.result.forEach((item) => {
          const data = item;
          data.value = item.name;
          return data;
        });
        this.rewardList = res.result;
      });
    },

    addProduct(index) {
      let functionCode = 'treety_modules_productList';
      let idKey = 'productAndLevelCode';
      if (this.productLevel === 'material') {
        functionCode = 'treety_modules_productList_material';
        idKey = 'materialCode';
      } else if (this.productLevel === 'all') {
        functionCode = 'treety_modules_productList';
        idKey = 'productAndLevelCode';
      } else if (this.productLevel === 'materialGroup') {
        functionCode = 'treety_modules_productList_materialGroup';
        idKey = 'materialGroupCode';
      } else {
        functionCode = 'treety_modules_productList_levelType';
        idKey = 'productLevelCode';
      }

      let productLevelType = null;
      switch (this.productLevel) {
        case 'brand':
          productLevelType = 'brand';
          break;
        case 'series':
          productLevelType = 'series';
          break;
        case 'max':
          productLevelType = 'max';
          break;
        case 'min':
          productLevelType = 'min';
          break;
        default:
          productLevelType = null;
          break;
      }
      const params = {
        functionCode,
        data: this.tableData[index].scanCodeRanges,
        selectionList: [],
        idKey,
        noReset: true,
        paramData: {
          productLevelType,
        },
      };
      this.modelIndex = index;
      this.$refs.productRef.openSelectModal(params);
    },
    clearSelectProduct(val) {
      this.scanCodeRanges = [];
      this.tableData.forEach((item) => {
        const data = item;
        if (val === 'all') {
          data.dimensionFlag = 'DIMENSION_ALL';
          data.dimensionName = '全部产品';
        } else {
          let dimensionName = '';
          this.productLevelList.forEach((p) => {
            if (p.dictCode === this.productLevel) dimensionName = p.dictValue;
          });
          data.dimensionFlag = val;
          data.dimensionName = dimensionName;
        }
        data.scanCodeRanges = [];
        return data;
      });
    },
    productConfirm(params) {
      console.log('商品弹框确认带出的数据', params);
      const scanCodeRanges = [];
      this.scanCodeRanges = [];
      params.forEach((item) => {
        if (this.productLevel === 'all') {
          scanCodeRanges.push({
            ...item,
            specialName: item.productAndLevelName,
            specialCode: item.productAndLevelCode,
          });
        } else if (this.productLevel === 'material') {
          scanCodeRanges.push({
            ...item,
            specialName: item.materialName,
            specialCode: item.materialCode,
          });
        } else if (this.productLevel === 'materialGroup') {
          scanCodeRanges.push({
            ...item,
            specialName: item.materialGroupName,
            specialCode: item.materialGroupCode,
          });
        } else {
          scanCodeRanges.push({
            ...item,
            specialName: item.productLevelName,
            specialCode: item.productLevelCode,
          });
        }
      });
      this.tableData[this.modelIndex].scanCodeRanges = scanCodeRanges;
      this.scanCodeRanges = scanCodeRanges;
    },
    closeTag(val, index) {
      this.tableData[index].scanCodeRanges = this.tableData[
        index
      ].scanCodeRanges.filter((item) => item.specialCode !== val.specialCode);
      this.scanCodeRanges = this.scanCodeRanges.filter(
        (item) => item.specialCode !== val.specialCode,
      );
    },

    addRow() {
      if (this.tableData.length > 0) return;
      let dimensionName = '';
      this.productLevelList.forEach((item) => {
        if (item.dictCode === this.productLevel) dimensionName = item.dictValue;
      });
      this.tableData.push({
        dimensionFlag:
          this.productLevel === 'all' ? 'DIMENSION_ALL' : this.productLevel,
        dimensionName: this.productLevel === 'all' ? '全部产品' : dimensionName,
        policyKey: 'scanCode',
        scanCodeParticipators: [],
        scanCodeRanges: [],
      });
    },
    delRow(index) {
      this.tableData.splice(index, 1);
    },

    // 新增奖励标准
    addStandard(type, index) {
      console.log(type, index);
      if (!type) return this.$message.warning('选择奖励对象');
      let typeName = '';
      this.typeOptions.forEach((item) => {
        if (type === item.value) typeName = item.label;
      });
      let hasType = false;
      this.tableData[index].scanCodeParticipators.forEach((item) => {
        if (item.participatorFlag === type) hasType = true;
      });
      if (hasType) return this.$message.error(`已有${typeName}奖励标准，无法重复创建`);
      let barCodeList = [];
      let rewardList = [];
      if (type === '1') {
        barCodeList = this.barCodeList.filter(
          (item) => item.dictValue === '箱码',
        );
        rewardList = this.rewardList.filter((item) => item.name === '费用' || item.name === '现金权益');
      } else if (type === '2') {
        barCodeList = this.barCodeList.filter(
          (item) => item.dictValue === '箱码',
        );
        rewardList = this.rewardList.filter(
          (item) => item.name === '红包' || item.name === '积分',
        );
      } else if (type === '3') {
        // barCodeList = this.barCodeList.filter(
        //   (item) => item.dictValue === '盖内码',
        // );
        barCodeList = this.barCodeList;
        rewardList = this.rewardList.filter((item) => item.name === '积分');
      }
      this.tableData[index].scanCodeParticipators.push({
        participatorFlag: type,
        participatorName: typeName,
        scanCodeExpressions: [
          {
            ladder: {
              text: '',
              rateList: [
                {
                  cash: '',
                  percent: '',
                },
              ],
              controls: [
                {
                  label: '每',
                  controlType: 'select',
                  selectOptions: barCodeList,
                  value: '',
                },
                {
                  label: '奖励',
                  controlType: 'select',
                  selectOptions: rewardList,
                  selectType: 'reward',
                  value: '',
                },
                {
                  controlType: 'select',
                  selectOptions: [],
                  value: '',
                },
                {
                  controlType: 'input',
                  type: 'number',
                  value: '',
                },
                {
                  controlType: 'labelPostfix',
                  value: '元',
                },
              ],
            },
            dealerList: [],
            terminalList: [],
          },
        ],
      });
    },
    // 删除奖励标准
    delStandard(rowIndex, index) {
      // 删除第rowIndex行 第index条
      this.tableData[rowIndex].scanCodeParticipators.splice(index, 1);
    },
    // 新增奖励标准详情
    addRowStandard(rowIndex, index, cIndex, child) {
      console.log(rowIndex, index, cIndex, child);
      // 在第rowIndex行 第index条奖励标准 新增一条详情
      const barCodeList = [];
      const rewardList = [];
      // if (index === 0) {
      //   barCodeList = this.barCodeList.filter(
      //     (item) => item.dictValue === '箱码',
      //   );
      //   // rewardList = this.rewardList.filter(
      //   //   (item) => item.name === '费用',
      //   // );
      // } else if (index === 1) {
      //   barCodeList = this.barCodeList.filter(
      //     (item) => item.dictValue === '箱码',
      //   );
      //   // rewardList = this.rewardList.filter(
      //   //   (item) => item.name === '红包' || item.name === '积分',
      //   // );
      // } else if (index === 2) {
      //   barCodeList = this.barCodeList.filter(
      //     (item) => item.dictValue === '盖内码',
      //   );
      // rewardList = this.rewardList.filter(
      //   (item) => item.name === '积分',
      // );
      // }
      this.tableData[rowIndex].scanCodeParticipators[
        index
      ].scanCodeExpressions.push({
        ladder: {
          text: '',
          rateList: [
            {
              cash: '',
              percent: '',
            },
          ],
          controls: [
            {
              label: '每',
              controlType: 'select',
              selectOptions: child.ladder.controls[0].selectOptions || [],
              value: '',
            },
            {
              label: '奖励',
              controlType: 'select',
              selectOptions: child.ladder.controls[1].selectOptions || [],
              selectType: 'reward',
              value: '',
            },
            {
              controlType: 'select',
              selectOptions: [],
              value: '',
            },
            {
              controlType: 'input',
              type: 'number',
              value: '',
            },
            {
              controlType: 'labelPostfix',
              value: '元',
            },
          ],
        },
        dealerList: [],
        terminalList: [],
      });
    },
    // 删除奖励标准详情
    delRowStandard(rowIndex, index, cIndex) {
      // 删除第rowIndex行 第index条 第cIndex个 奖励标准详情
      this.tableData[rowIndex].scanCodeParticipators[
        index
      ].scanCodeExpressions.splice(cIndex, 1);
    },
    // 新增经销商标准
    addDealerLadder(rowIndex, index, cIndex) {
      console.log(
        '新增经销商标准',
        this.tableData[rowIndex].scanCodeParticipators[index]
          .scanCodeExpressions[cIndex].dealerList,
      );
      console.log(this.rewardList);
      // 在第rowIndex行 第index条奖励标准 新增奖励经销商

      let rewardList = [];

      rewardList = this.rewardList.filter(
        (item) => item.name === '费用' || item.name === '现金权益',
      );

      this.tableData[rowIndex].scanCodeParticipators[index].scanCodeExpressions[
        cIndex
      ].dealerList.push({
        text: '',
        rateList: [
          {
            cash: '',
            percent: '',
          },
        ],
        controls: [
          {
            label: '奖励经销商',
            controlType: 'select',
            selectOptions: rewardList,
            selectType: 'reward',
            value: '',
          },
          {
            controlType: 'select',
            selectOptions: [],
            value: '',
          },
          {
            controlType: 'input',
            type: 'number',
            value: '',
          },
          {
            controlType: 'labelPostfix',
            value: '元',
          },
        ],
      });
    },
    delDealerLadder(rowIndex, index, cIndex, dIndex) {
      // 删除经销商标准
      this.tableData[rowIndex].scanCodeParticipators[index].scanCodeExpressions[
        cIndex
      ].dealerList.splice(dIndex, 1);
    },
    // 新增终端标准
    addTerminalLadder(rowIndex, index, cIndex) {
      // 在第rowIndex行 第index条奖励标准 新增奖励经销商
      let rewardList = [];
      rewardList = this.rewardList.filter(
        (item) => item.name === '红包' || item.name === '积分',
      );
      this.tableData[rowIndex].scanCodeParticipators[index].scanCodeExpressions[
        cIndex
      ].terminalList.push({
        text: '',
        rateList: [
          {
            cash: '',
            percent: '',
          },
        ],
        controls: [
          {
            label: '奖 励 终 端',
            controlType: 'select',
            selectOptions: rewardList,
            selectType: 'reward',
            value: '',
          },
          {
            controlType: 'select',
            selectOptions: [],
            value: '',
          },
          {
            controlType: 'input',
            type: 'number',
            value: '',
          },
          {
            controlType: 'labelPostfix',
            value: '元',
          },
        ],
      });
    },
    delTerminalLadder(rowIndex, index, cIndex, tIndex) {
      // 删除终端标准
      this.tableData[rowIndex].scanCodeParticipators[index].scanCodeExpressions[
        cIndex
      ].terminalList.splice(tIndex, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.form-header {
  margin: 18px 0;
}
.fixTitle {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.rowSection {
  position: relative;
  border: 1px solid #d3d5d8;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0;
}

.rowLineIcon {
  display: flex;
  justify-content: space-between;
}

.close {
  display: inline-block;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45) !important;
  position: absolute;
  right: 2px;
  top: 2px;

  &:hover {
    color: rgba(0, 0, 0, 0.8) !important;
  }
}
</style>
