/*
 * @Author: yzr
 * @Date: 2021-07-19 16:05:16
 * @Last Modified by:   yzr
 * @Last Modified time: 2021-07-19 16:05:16
 */
import Form from './form.vue';
import FormPure from './form-copy.vue';

export default Form;
