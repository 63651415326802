<!--
 * @Author: yzr
 * @Date: 2020-12-03 10:32:09
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-13 11:22:38
-->
<template>
  <div>
    <!-- <span v-if="type === 'condition'">示例：</span> -->
    <span>{{ ladder.text }}</span>
    <span v-for="(control, index) in ladder.controls" :key="index">
      <label>{{ control.label }}</label>
      <el-input :type="control.type" @change="(val) => inputChange(ladder, control)" class='renderInput' v-if="control.controlType === 'input' && !control.hide" v-model="control.value" :placeholder="control.description" :min='0' :disabled="!notView"></el-input>
      <el-select @change="(val) => selectChange(val ,index, control, ladder)" class='renderSelect' v-if="control.controlType === 'select'" v-model="control.value" :placeholder="control.description" :disabled="!notView">
        <el-option v-for="item in control.selectOptions" :key="item.key" :label="item.value" :value="item.key">
        </el-option>
      </el-select>
      <span v-if="control.controlType === 'labelPostfix' && (!hide && !ladder.hide)">{{control.value}}</span>
    </span>
    <div v-if="hide || ladder.hide">
      <div class="rate" v-for="(rate, index) in ladder.rateList" :key="index">
        <div>
          <span>{{`定额${index + 1}`}}</span>
          <el-input :disabled="!notView" v-model="rate.cash" @change="() => inputChange(ladder, null, index)" class="rateInput" type="text" />
          <span>{{ladder.controls[ladder.controls.length - 1].value}}</span>
          <el-input :disabled="!notView" v-model="rate.percent" @change="(e) => inputChange(ladder, null, index)" class="rateInput" type="text" />
          <span>%</span>
        </div>
        <a v-if="index === 0 && notView" class="el-icon-circle-plus-outline rowLineIcon" @click="() => addRate(index)"></a>
        <a v-else-if="index !== 0 && notView" class="el-icon-remove-outline rowLineIcon closeIcon" @click="() => delRate(index)"></a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'RuleRender',
  props: {
    ladder: {
      type: Object,
      default: () => {},
    },
    type: String,
    notView: Boolean,
  },
  data() {
    return {
      hide: false,
      rewardType: 'red',
    };
  },
  watch: {
    ladder(nv) {
      // console.log(this.type, nv);
      console.log(nv);
    },
  },
  methods: {
    inputChange(ladder, control, index) {
      this.$parent.saveLadder(this.ladder);
      // console.log('control', ladder);

      // 主体输入框
      const num = control;
      if (control && control.type === 'number') {
        if (this.rewardType === 'red') num.value = Number(control.value).toFixed(2);
        if (this.rewardType === 'int') num.value = Number(control.value).toFixed(0);
        return num;
      }

      ladder.rateList.forEach((item) => {
        const data = item;
        data.percent = data.percent ? Number(data.percent).toFixed(2) : '';
        data.cash = ladder.controls[ladder.controls.length - 1].value === '积分' ? Number(data.cash).toFixed(0) : Number(data.cash).toFixed(2);
        data.totalRate += Number(item.percent);
        // if (totalRate > 100) return this.$message.warning('奖励几率总和应小于100%');
        return data;
      });
    },

    selectChange(val, index, control, ladder) {
      if (control.selectType === 'reward') {
        control.selectOptions.forEach((element) => {
          if (element.key === val) {
            element.rewardComputeTypeMountVos.forEach((item) => {
              const data = item;
              data.value = item.name;
              return data;
            });
            this.ladder.controls[index + 1].selectOptions = element.rewardComputeTypeMountVos;
          }
        });
      }

      if (val === 'randomAmount') {
        this.ladder.controls[index + 1].hide = true;
        this.hide = true;
      } else if (val === 'fixedAmount') {
        this.ladder.controls[index + 1].hide = false;
        this.hide = false;
      }

      if (val === 'integral') {
        this.rewardType = 'int';
        if (this.ladder.controls[index + 3].controlType === 'labelPostfix') this.ladder.controls[index + 3].value = '积分';
      } else if (val === 'redpacket' || val === 'cost') {
        this.rewardType = 'red';
        if (this.ladder.controls[index + 3].controlType === 'labelPostfix') this.ladder.controls[index + 3].value = '元';
      }

      this.$parent.saveLadder(this.ladder);
    },

    addRate(index) {
      this.ladder.rateList.push({
        percent: '',
        cash: '',
      });
      this.$parent.saveLadder(this.ladder);
    },
    delRate(index) {
      this.ladder.rateList.splice(index, 1);
      this.$parent.saveLadder(this.ladder);
    },
  },
};
</script>

<style lang="less" scoped>
.renderItem {
  margin: 5px 0;
}
.renderInput {
  width: 125px;
  margin: 0 5px;
  display: inline-block;
}
.renderSelect {
  width: 150px;
  margin: 0 5px;
  display: inline-block;
}

.rate {
  display: flex;
  margin-left: 20px;
}
.rateInput {
  width: 60px;
  margin: 0 5px;
  display: inline-block;
}
.rowLineIcon {
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  padding-left: 10px;
}
.closeIcon {
  color: #f56c6c !important;
}
</style>
