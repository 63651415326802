var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "fixTitle" },
        [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("span", [_vm._v("产品维度：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", disabled: _vm.notView },
                  on: { change: _vm.clearSelectProduct },
                  model: {
                    value: _vm.productLevel,
                    callback: function ($$v) {
                      _vm.productLevel = $$v
                    },
                    expression: "productLevel",
                  },
                },
                _vm._l(_vm.productLevelList, function (item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          !_vm.notView
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addRow },
                },
                [_vm._v("添加政策")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { style: { maxHeight: _vm.maxTableHeight, overflowY: "auto" } },
        _vm._l(_vm.tableData, function (tableItem, index) {
          return _c(
            "div",
            { key: index, staticClass: "rowSection" },
            [
              !_vm.notView
                ? _c("a", {
                    staticClass: "el-icon-close close",
                    on: {
                      click: function () {
                        return _vm.delRow(index)
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { background: "#f9f9f9", padding: "0 20px" } },
                [
                  _c("div", { staticClass: "rowLineIcon" }, [
                    _c("span", [_vm._v("产品：")]),
                    !_vm.notView && _vm.productLevel !== "all"
                      ? _c(
                          "a",
                          {
                            staticClass: "el-icon-circle-plus-outline",
                            staticStyle: { "line-height": "36px" },
                            on: {
                              click: function () {
                                return _vm.addProduct(index)
                              },
                            },
                          },
                          [_vm._v("添加产品")]
                        )
                      : _vm._e(),
                  ]),
                  _vm._l(tableItem.scanCodeRanges, function (tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag.specialCode,
                        staticStyle: { "margin-right": "5px" },
                        attrs: { closable: !_vm.notView, effect: "plain" },
                        on: {
                          close: function () {
                            return _vm.closeTag(tag, index)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(tag.specialName) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c("span", [_vm._v("扫码奖励标准：")]),
              _c("RewordStandard", {
                attrs: {
                  notView: _vm.notView,
                  rowIndex: index,
                  list: tableItem.scanCodeParticipators,
                  addStandard: _vm.addStandard,
                  delStandard: _vm.delStandard,
                  addRowStandard: _vm.addRowStandard,
                  delRowStandard: _vm.delRowStandard,
                  addTerminalLadder: _vm.addTerminalLadder,
                  delTerminalLadder: _vm.delTerminalLadder,
                  addDealerLadder: _vm.addDealerLadder,
                  delDealerLadder: _vm.delDealerLadder,
                },
              }),
              tableItem.scanCodeParticipators.length < 3 && !_vm.notView
                ? _c(
                    "div",
                    { staticStyle: { "padding-left": "100px" } },
                    [
                      (
                        tableItem.scanCodeParticipators.length
                          ? tableItem.scanCodeParticipators.every(function (
                              item
                            ) {
                              return item.participatorFlag !== "1"
                            })
                          : true
                      )
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", icon: "el-icon-plus" },
                              on: {
                                click: function () {
                                  return _vm.addStandard("1", index)
                                },
                              },
                            },
                            [_vm._v(" 经销商出库 ")]
                          )
                        : _vm._e(),
                      (
                        tableItem.scanCodeParticipators.length
                          ? tableItem.scanCodeParticipators.every(function (
                              item
                            ) {
                              return item.participatorFlag !== "2"
                            })
                          : true
                      )
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", icon: "el-icon-plus" },
                              on: {
                                click: function () {
                                  return _vm.addStandard("2", index)
                                },
                              },
                            },
                            [_vm._v(" 终端 ")]
                          )
                        : _vm._e(),
                      (
                        tableItem.scanCodeParticipators.length
                          ? tableItem.scanCodeParticipators.every(function (
                              item
                            ) {
                              return item.participatorFlag !== "3"
                            })
                          : true
                      )
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", icon: "el-icon-plus" },
                              on: {
                                click: function () {
                                  return _vm.addStandard("3", index)
                                },
                              },
                            },
                            [_vm._v(" 消费者 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _c("FormHeader", {
        staticClass: "form-header",
        attrs: { visible: true, title: "扫码分利规则概述" },
      }),
      _c("el-input", {
        attrs: {
          disabled: _vm.notView,
          type: "textarea",
          placeholder: "请简要说明分利规则",
        },
        model: {
          value: _vm.remark,
          callback: function ($$v) {
            _vm.remark = $$v
          },
          expression: "remark",
        },
      }),
      _c("SelectConfig", {
        ref: "productRef",
        on: { onGetSelect: _vm.productConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }