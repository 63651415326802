<!--
 * @Autor: yzr
 * @Date: 2021-07-19 16:30:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-15 18:20:21
 * @Description: 新增协议模板配置-标签页组件
-->

<template>
  <div>
    <p v-show="!notView" class="title">协议包含政策类型(单选)</p>
    <!-- <el-checkbox-group v-show="!notView" v-model="modules" @change="handleChange">
      <el-checkbox-button @change="closeCheckbox" :disabled='notView' v-for="policy in policyList" :label="policy.key" :key="policy.key">{{policy.name}}</el-checkbox-button>
    </el-checkbox-group> -->
    <el-radio-group v-show="!notView" v-model="modules" @change="handleChange">
      <el-radio-button @change="closeCheckbox" :disabled='notView' v-for="policy in policyList" :label="policy.key" :key="policy.key">{{policy.name}}</el-radio-button>
    </el-radio-group>
    <el-tabs class="tabs" v-model="activeName">
      <el-tab-pane
        v-for="(item) in enableTabs"
        :key="item.key"
        :label="item.name"
        :name="item.key"
      >
      </el-tab-pane>
    </el-tabs>

    <PackageNum
      v-show="activeName === 'quantifyPolicy'"
      :notView="notView"
      ref='PackageNum'
      :productLevelList="productLevelList"
      :rewardList="rewardList"
      :weekOptions="weekOptions"

    />
    <Display
      v-show="activeName === 'displayPolicy'"
      :notView="notView"
      ref="Display"
      :productLevelList="productLevelList"
      :rewardList="rewardList.filter(item=>{
          return item.key ==='gift'
      })"
      :displayTypeList="displayTypeList"
    />
    <ScanTable
      v-show="activeName === 'scanCode'"
      :notView="notView"
      ref="ScanTable"
      :productLevelList="productLevelList"
      :rewardList="rewardList"
      :barCodeList="barCodeList"
      @changeDealer="changeDealer"
    />
    <TreetyContent v-show="activeName === 'text'" :notView="notView" ref="TreetyContent" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import ScanTable from './ScanTable.vue';
import TreetyContent from './TreeTyContent.vue';
import PackageNum from './PackageNum.vue';
// import Display from './DisplayPure.vue';
import Display from './Display.vue';

export default {
  name: 'AddForm',
  components: {
    ScanTable,
    TreetyContent,
    PackageNum,
    Display,
  },
  data() {
    return {
      activeName: 'text',
      policyList: [],
      modules: [],
      details: [],
      openTag: true,
      notView: false,
      enableTabs: [
        {
          name: '协议文本',
          key: 'text',
        },
      ],

      // 数据字典
      productLevelList: [],
      rewardList: [],
      barCodeList: [],
      weekOptions: [],
      displayTypeList: [],
      templateDealerRelationships: [], // 经销商类型
    };
  },
  watch: {
    details(v) {
      if (v.length) {
        v.forEach((item) => {
          // 扫码政策
          if (item.policyKey === 'scanCode') this.$refs.ScanTable.recieveData = item.scanCodeConfigurationVos;
          // 包量政策
          if (item.policyKey === 'quantifyPolicy') this.$refs.PackageNum.recieveData = item;
          // 陈列政策
          if (item.policyKey === 'displayPolicy') this.$refs.Display.recieveData = item;
        });
      }
    },
  },
  mounted() {
    this.getDict();
  },
  methods: {
    // 接受经销商类型
    changeDealer(templateDealerRelationships) {
      this.$emit('change', templateDealerRelationships);
    },
    handleChange(val) {
      // 添加页签
      this.policyList.forEach((policy) => {
        if (val === policy.key && !this.enableTabs.filter((tab) => tab.key === val).length) {
          this.enableTabs.splice(1, 1, policy);
          this.activeName = val;
        }
      });
      // 关闭tab页签
      if (!this.openTag) {
        const newTabs = [
          {
            name: '协议文本',
            key: 'text',
          },
        ];

        let obj = {};
        obj = this.enableTabs.find((tab) => tab.key === val);
        if (Object.keys(obj).length) newTabs.splice(1, 1, obj);

        this.enableTabs = newTabs;
        this.activeName = newTabs[newTabs.length - 1].key;
      }
    },
    // 记录增删页签状态
    closeCheckbox(v) {
      this.openTag = v;
    },
    // 获取数据字典
    getDict() {
      // 包含政策类型
      request.get('/cps/v1/agreement/mount/findPolicy', {
        dictTypeCode: 'treety_policy_type',
      }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.policyList = res.result;
        // this.policyList = this.policyList.concat([{ key: '2', name: '陈列政策' }]);
      });

      // 产品维度
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'cps-product-level',
      }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.productLevelList = res.result.filter((item) => item.dictCode === 'materialGroup');
      });

      // 产品维度
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'DisplayType',
      }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.displayTypeList = res.result;
      });

      // 条码类型
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'BarCodeType',
      }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        res.result.forEach((item) => {
          const data = item;
          data.key = item.dictCode;
          data.value = item.dictValue;
          return data;
        });
        this.barCodeList = res.result;
      });

      // 奖励类型
      request.get('/cps/v1/reward/mount/findReward').then((res) => {
        if (!res.success) return this.$message.error(res.message);
        res.result.forEach((item) => {
          const data = item;
          data.value = item.name;
          return data;
        });
        this.rewardList = res.result;
      });

      // 包量计算周期
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'calculate_cycle',
      }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        res.result.forEach((item) => {
          const data = item;
          data.key = item.dictCode;
          data.value = item.dictValue;
          return data;
        });
        this.weekOptions = res.result;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tabs {
  .el-tabs__content {
    height: 460px;
  }
}
.title {
  &::before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
  }
}
</style>
