<!--
 * @Autor: yzr
 * @Date: 2021-07-19 14:23:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-03 11:26:07
 * @Description: 协议模板主体列表
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form';

export default {
  name: 'modules',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps/v1/agreement/template/findByConditions',
      params: {
      },
      formConfig: {},
      modalConfig: {
      },
      searchFormData: {},
      formData: {},
    };
  },
  async created() {
    await this.getConfigList('treety_modules_list');
  },
  async mounted() {
    await this.getPath();
  },
  deactivated() {
    this.formData = {};
    this.searchFormData = {};
    this.getList();
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      return rowData;
    },
    modalClick({ val, row }) {
      this.modalConfig = {
        title: '协议模板配置',
      };
      switch (val.code) {
        case 'add':
          this.openFull();
          break;
        case 'view':
          this.formConfig.id = row.id;
          this.openFull();
          break;
        case 'enable':
          this.disable(false);
          break;
        case 'disable':
          this.disable(true);
          break;
        default:
          break;
      }
    },
    disable(type) {
      let url = '/cps/v1/agreement/template/enable';
      if (type) url = '/cps/v1/agreement/template/disable';
      request.patch(url, this.selection).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.$message.success(res.message);
        this.getList();
      });
    },
    clickVisible({ val, row }) {
      return true;
    },
    // 监听页面跳转
    getPath() {
      if (Object.keys(this.$route.params).length !== 0) {
        this.formData.templateCode = this.$route.params.templateCode;
        // this.searchFormData = {
        //   ...this.searchFormData,
        //   templateCode: this.$route.params.templateCode,
        // };
        this.searchFormData.templateCode = this.$route.params.templateCode;
        this.getList();
        // this.searchFormData = {};
      }
    },
  },
  watch: {
    $route: 'getPath',
  },
};
</script>
