var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticStyle: { margin: "20px 0" } }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c("span", [_vm._v("政策规则配置：")]),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-plus",
                  disabled: _vm.notView,
                },
                on: { click: _vm.addConfig },
              },
              [_vm._v("添加阶梯")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ladderTableClass" },
          [
            _c(
              "vxe-table",
              {
                ref: "jTable",
                attrs: {
                  border: "",
                  "show-header-overflow": "",
                  "show-overflow": "",
                  "highlight-hover-row": "",
                  data: _vm.ladderArr,
                  "show-footer": "",
                },
              },
              [
                _vm._l(_vm.tableLadderInfo.columns, function (col, lIndex) {
                  return _c("vxe-table-column", {
                    key: lIndex,
                    attrs: {
                      field: col.field,
                      title: col.title,
                      "min-width": col.width ? col.width : 80,
                      fixed: col.fixed,
                    },
                  })
                }),
                _c(
                  "vxe-table-column",
                  { attrs: { width: "100px", title: "操作" } },
                  [
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { underline: false },
                          on: {
                            click: function () {
                              return _vm.delLadeer(_vm.index, _vm.lIndex)
                            },
                          },
                        },
                        [_vm._v(" 删除 ")]
                      ),
                    ],
                  ],
                  2
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "weekly" },
        [
          _c("span", [_vm._v("陈列类型：")]),
          _c(
            "el-select",
            {
              attrs: { disabled: _vm.notView },
              model: {
                value: _vm.displayType,
                callback: function ($$v) {
                  _vm.displayType = $$v
                },
                expression: "displayType",
              },
            },
            _vm._l(_vm.displayTypeList, function (option) {
              return _c("el-option", {
                key: option.dictCode,
                attrs: { value: option.dictCode, label: option.dictValue },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm._l(_vm.uploadRules, function (fre, index) {
            return _c(
              "div",
              {
                key: index,
                staticStyle: { display: "flex", margin: "0 0 10px" },
              },
              [
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s("第" + (index + 1) + "次上传时间：")),
                ]),
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { disabled: _vm.notView },
                    model: {
                      value: fre.startDay,
                      callback: function ($$v) {
                        _vm.$set(fre, "startDay", $$v)
                      },
                      expression: "fre.startDay",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "apend",
                        attrs: { slot: "append" },
                        slot: "append",
                      },
                      [_vm._v("号")]
                    ),
                  ]
                ),
                _vm._v("  至  "),
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { disabled: _vm.notView },
                    model: {
                      value: fre.endDay,
                      callback: function ($$v) {
                        _vm.$set(fre, "endDay", $$v)
                      },
                      expression: "fre.endDay",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "apend",
                        attrs: { slot: "append" },
                        slot: "append",
                      },
                      [_vm._v("号")]
                    ),
                  ]
                ),
                index === 0 && !_vm.notView
                  ? _c("a", {
                      staticClass: "el-icon-circle-plus-outline rowLineIcon",
                      on: {
                        click: function () {
                          return _vm.addFre()
                        },
                      },
                    })
                  : !_vm.notView
                  ? _c("a", {
                      staticClass: "el-icon-circle-close rowLineIcon closeIcon",
                      on: {
                        click: function () {
                          return _vm.delFre(index)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "weekly" },
            [
              _c("span", { staticStyle: { "margin-right": "18px" } }, [
                _vm._v("陈列示例图片："),
              ]),
              _c(
                "el-upload",
                {
                  attrs: {
                    headers: _vm.headerObj,
                    action: "/upload/uploadController/uploadOssList",
                    "with-credentials": true,
                    "list-type": "picture-card",
                    "auto-upload": true,
                    "on-preview": _vm.handlePictureCardPreview,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.handleSuccess,
                    limit: 6,
                    "file-list": _vm.displaySampleGraphs,
                    accept: ".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF",
                    disabled: _vm.notView,
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: { visible: _vm.dialogVisible, modal: false },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("div", { staticStyle: { margin: "20px 0" } }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("span", [_vm._v("陈列产品维度：")]),
                _c(
                  "el-select",
                  {
                    attrs: { disabled: _vm.notView },
                    on: { change: _vm.clearSelectProduct },
                    model: {
                      value: _vm.productLevel,
                      callback: function ($$v) {
                        _vm.productLevel = $$v
                      },
                      expression: "productLevel",
                    },
                  },
                  _vm._l(_vm.productLevelList, function (option) {
                    return _c("el-option", {
                      key: option.dictCode,
                      attrs: {
                        value: option.dictCode,
                        label: option.dictValue,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            !_vm.notView
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", icon: "el-icon-plus" },
                    on: { click: _vm.addRow },
                  },
                  [_vm._v("添加政策")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          _vm._l(_vm.tableData, function (tableItem, index) {
            return _c("div", { key: index, staticClass: "rowSection" }, [
              !_vm.notView
                ? _c("a", {
                    staticClass: "el-icon-close close",
                    on: {
                      click: function () {
                        return _vm.delRow(index)
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { background: "#f9f9f9", padding: "0 20px" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c("span", [_vm._v("产品：")]),
                    !_vm.notView && _vm.productLevel !== "all"
                      ? _c(
                          "a",
                          {
                            staticClass: "el-icon-circle-plus-outline",
                            staticStyle: { "line-height": "36px" },
                            on: {
                              click: function () {
                                return _vm.addProduct(index)
                              },
                            },
                          },
                          [_vm._v("添加产品")]
                        )
                      : _vm._e(),
                  ]),
                  _vm._l(tableItem.displayPolicyRanges, function (tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag.specialCode,
                        staticStyle: { "margin-right": "5px" },
                        attrs: { closable: !_vm.notView, effect: "plain" },
                        on: {
                          close: function () {
                            return _vm.closeTag(tag, index)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(tag.specialName) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    background: "#f9f9f9",
                    padding: "20px",
                    "margin-top": "10px",
                  },
                },
                [
                  _c("div", { staticClass: "titleflex" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: {
                              disabled: _vm.notView,
                              label: "Y",
                              border: "",
                              size: "mini",
                            },
                            model: {
                              value: tableItem.awardType,
                              callback: function ($$v) {
                                _vm.$set(tableItem, "awardType", $$v)
                              },
                              expression: "tableItem.awardType",
                            },
                          },
                          [_vm._v("按最高达成奖励")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "table",
                    [
                      _c(
                        "tr",
                        [
                          _c("td", [_vm._v("操作")]),
                          _c("td", [_vm._v("阶梯达成比例（%）")]),
                          _c("td", [_vm._v("奖励类型")]),
                          _c("td", [_vm._v("奖励名称")]),
                          _c("td", [_vm._v("奖励周期（可多选）")]),
                          _vm._l(_vm.arrTag, function (tag, tIndex) {
                            return _c("td", { key: tIndex }, [
                              _vm._v(_vm._s(tag) + "度达成奖励数量"),
                            ])
                          }),
                        ],
                        2
                      ),
                      _vm._l(
                        tableItem.displayPolicyExpressions,
                        function (item, rIndex) {
                          return _c(
                            "tr",
                            { key: rIndex },
                            [
                              _c(
                                "td",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { disabled: _vm.notView },
                                      on: {
                                        click: function () {
                                          return _vm.deleteRow(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticStyle: {} },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      controls: false,
                                      placeholder: "请输入阶梯达成比例",
                                      disabled: _vm.notView,
                                    },
                                    model: {
                                      value: item.reachRate,
                                      callback: function ($$v) {
                                        _vm.$set(item, "reachRate", $$v)
                                      },
                                      expression: "item.reachRate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticStyle: {} },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "tableItem",
                                      staticStyle: {
                                        "margin-bottom": "0",
                                        display: "inline-block",
                                        width: "100px",
                                      },
                                      attrs: { disabled: _vm.notView },
                                      on: {
                                        change: function (val) {
                                          return _vm.rewardChange(
                                            val,
                                            rIndex,
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.rewardMethodFlag,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "rewardMethodFlag",
                                            $$v
                                          )
                                        },
                                        expression: "item.rewardMethodFlag",
                                      },
                                    },
                                    _vm._l(_vm.rewardList, function (option) {
                                      return _c("el-option", {
                                        key: option.key,
                                        attrs: {
                                          value: option.key,
                                          label: option.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      item.rewardMethodFlag === "gift"
                                        ? _c(
                                            "el-link",
                                            {
                                              attrs: { disabled: _vm.notView },
                                              on: {
                                                click: function () {
                                                  return _vm.addInKind(
                                                    index,
                                                    rIndex
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("添加实物奖励")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("td", { staticStyle: { width: "100px" } }, [
                                item.rewardMethodFlag === "redpacket"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s((item.rewardName = "红包奖励"))
                                      ),
                                    ])
                                  : item.rewardMethodFlag === "cost"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s((item.rewardName = "费用奖励"))
                                      ),
                                    ])
                                  : item.rewardMethodFlag === "integral"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s((item.rewardName = "积分奖励"))
                                      ),
                                    ])
                                  : item.rewardMethodFlag === "cash"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s((item.rewardName = "现金奖励"))
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(item.rewardName)),
                                    ]),
                              ]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: _vm.notView,
                                        multiple: "",
                                        placeholder: "请选择",
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.changTag(
                                            val,
                                            index,
                                            rIndex
                                          )
                                        },
                                        "remove-tag": function (val) {
                                          return _vm.removeTag(
                                            val,
                                            index,
                                            rIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.tagArr,
                                        callback: function ($$v) {
                                          _vm.$set(item, "tagArr", $$v)
                                        },
                                        expression: "item.tagArr",
                                      },
                                    },
                                    _vm._l(item.selectArr, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(item.textArr, function (num, jIndex) {
                                return _c(
                                  "td",
                                  { key: jIndex },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.notView,
                                        placeholder:
                                          num.value +
                                          "度达成奖励" +
                                          (item.rewardMethodFlag === "gift"
                                            ? "数量"
                                            : "现金"),
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.formatterNum(val, num)
                                        },
                                      },
                                      model: {
                                        value: num.number,
                                        callback: function ($$v) {
                                          _vm.$set(num, "number", _vm._n($$v))
                                        },
                                        expression: "num.number",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]
              ),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "weekly" },
        [
          _c("span", [_vm._v("绑定销量目标：")]),
          _c(
            "el-radio-group",
            {
              attrs: { disabled: _vm.notView },
              model: {
                value: _vm.bindSaleStatus,
                callback: function ($$v) {
                  _vm.bindSaleStatus = $$v
                },
                expression: "bindSaleStatus",
              },
            },
            [
              _c("el-radio", { attrs: { label: "0" } }, [_vm._v("是")]),
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("否")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.bindSaleStatus === "0"
        ? _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                border: "",
                "show-header-overflow": "",
                "show-overflow": "",
                "highlight-hover-row": "",
                data: _vm.tableInfo.data,
                "show-footer": "",
                "edit-config": { trigger: "click", mode: "row" },
              },
            },
            _vm._l(_vm.tableInfo.columns, function (col, index) {
              return _c("vxe-table-column", {
                key: index,
                attrs: {
                  field: col.field,
                  title: col.title,
                  "edit-render":
                    col.editRender &&
                    Object.assign({}, col.editRender, {
                      props: Object.assign({}, col.editRender.props, {
                        disabled: _vm.tableDisable(_vm.notView, col.field),
                      }),
                    }),
                  "min-width": col.width ? col.width : 80,
                  fixed: col.fixed,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c("span", [_vm._v("陈列说明：")]),
          _c("el-input", {
            attrs: {
              disabled: _vm.notView,
              rows: 3,
              type: "textarea",
              placeholder:
                "（1）陈列需要显眼，陈列拍照请拍光线充足情况下的正面照片\n（2）如果15号后才签协议，陈列只能从下个月进行计算，当月扫码销量滚动计入下个月进行处理\n（3）费用审核后发放，可向经销商核销，冲抵货款",
            },
            model: {
              value: _vm.displayExplain,
              callback: function ($$v) {
                _vm.displayExplain = $$v
              },
              expression: "displayExplain",
            },
          }),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "productRef",
        on: { onGetSelect: _vm.productConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }